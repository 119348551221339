const TIMEOUT = 5000

// Actions
const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';
const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

// Reducers
const initialState = {
	messages: [],
	id: 0
};

export default (state=initialState, action) => {
	switch (action.type) {
		case PUSH_NOTIFICATION:
			return {
				messages: [...state.messages, {id: state.id, ...action.message, time: action.time}],
				id: state.id+1
			};

		case REMOVE_NOTIFICATION:
			let messages = [
				...state.messages.slice(1)
			];
			return {
				...state,
				messages: messages
			};

		default:
			return state;
	}
}

// Action creators
export const pushNotification = (message) => (dispatch, getState) => {
	let payload = getState().notifications.id;
	let d = new Date();
	// eslint-disable-next-line
	const H = new String(d.getHours()).padStart(2, "0");
	// eslint-disable-next-line
	const m = new String(d.getMinutes()).padStart(2, "0");
	// eslint-disable-next-line
	const s = new String(d.getSeconds()).padStart(2, "0");
    const time = H + ':' + m + ':' + s;
	dispatch({
		type: PUSH_NOTIFICATION,
		message,
		time
	});
	const duration = message.duration ? message.duration : 1;
	const timeout = TIMEOUT * duration;
	setTimeout(() => {
		dispatch({
			type: REMOVE_NOTIFICATION,
			payload
		})
	}, timeout);
}
