import config from  '../../package.json';

let constantsList = {};
if (config.modules)
	config.modules.forEach((module) => {
		try {
			let constants = require(`../modules/${module}/constants`).default;
			if (constants) {
				for (let key in constants)
					constantsList = { ...constantsList, [key]: { ...constantsList[key], ...constants[key] } };
			}
		} catch (ex) {

		}
	});

export default {...constantsList};
