// Actions

const CONTEXT_INIT = 'context/INIT';
const CONTEXT_SET = 'context/SET';


// Initial State

const initialState = {};


// Reducer

export default (state = initialState, action) => {
	switch (action.type) {
		case CONTEXT_INIT:
			return {
				...action.context,
			}

		case CONTEXT_SET:
			return {
				...state,
				...action.context,
			}

		default:
			return state;
	}
}


// Action creators

export const initContext = (context) => ({
	type: CONTEXT_INIT,
	context,
});

export const setContext = (context) => ({
	type: CONTEXT_SET,
	context,
});
