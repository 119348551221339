import { Api } from '../../../core/api';
import { pushNotification } from '../../../core/ducks/notifications';


// Actions
const BEGIN_PARSE = 'api/parsing/BEGIN';
const COMPLETE_PARSE = 'api/parsing/COMPLETE';
const FAILED_PARSE = 'api/parsing/FAILED';
const RESET_PARSE = 'api/parsing/RESET';

// Initial state
const initialState = {
	form: '',
	values: {},
	status: '',
};


// Reducer

export default (state=initialState, action) => {
	switch (action.type) {

		case BEGIN_PARSE:
			return {
				...state,
				form: action.form,
			}

		case COMPLETE_PARSE:
		case FAILED_PARSE:
			return {
				...state,
				values: action.values,
				status: action.status
			};

		case RESET_PARSE:
			return {
				...state,
				...initialState
			}

		default:
			return state;
	}
}


// Action creators

const beginParse = (form) => ({
	type: BEGIN_PARSE,
	form
});

const completeParse = (values, status) => ({
	type: COMPLETE_PARSE,
	values,
	status
});

const failedParse = (status) => ({
	type: FAILED_PARSE,
	values: {},
	status
});

export const resetParse = () => ({
	type: RESET_PARSE,
});


// Thunk action creators
export const parse = (uuid, form, application) => (dispatch) => {
	dispatch(beginParse(form));
	if (uuid === '') {
		dispatch(completeParse({}, ''));
	} else {
		let a = new Api(`parsePdf/uuid/${uuid}/form/${form}/application/${application}`);
		let status;
		a.Get().then((response) => {
			status = response.status;
			return response.json();
		}).then((json) => {
			if (status === 200) {
				dispatch(completeParse(json, status));
			} else {
				dispatch(failedParse(status));
				dispatch(pushNotification({body: 'error parsing file', type: 'warning'}));
			}
		})
	}
}
