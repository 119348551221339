import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import './App.scss';

import { basename } from '../history';
import Content from './views/content';

class App extends Component {

	render() {
		return (
			<BrowserRouter basename={basename}>
				<Route path="/" component={Content}/>
			</BrowserRouter>
		);
	}
}

export default App;
