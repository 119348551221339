import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Row,
    Col,
    FormGroup, Label, Input,
    Button
} from 'reactstrap';
import { requestData } from "core/ducks/list";
import { Loading, Error } from 'core/components';
import { Line, Bar } from 'react-chartjs-2';

class StatisticsSection2 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pollutantSelect: '',
            stationSelect: null,
            fromSelect: '',
            toSelect: '',
            thresholdSelect: null,
            loading: true,
            dataDaily: false,
            dataWeekly: false,
            dataMonthly: false,
            threshold: false,
            startLoading: false,
            stations: [],
            error: false
        };

        this.fetchStations = this.fetchStations.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        this.fetchStations();
    }

    fetchStations() {
        let self = this;
        this.props.dispatch(requestData('statsStationData', 'thing/list/')).then(() => {
            const d = self.props.statsStationDataList;

            let stations = [];
            Object.keys(d).forEach(function (key, index) {
                stations.push({ token: key, description: d[key] });
            });

            self.setState({
                stations: stations
            })
        });
    }

    fetchData() {
        let self = this;
        self.setState({
            loading: true,
            dataDaily: false,
            dataWeekly: false,
            dataMonthly: false,
            threshold: false,
            startLoading: true,
            error: false
        });

        this.props.dispatch(requestData('statsThingData', 'stats/thing/' + self.state.stationSelect + '/property/' + self.state.pollutantSelect + '/start/' + self.state.fromSelect + '/end/' + self.state.toSelect + '/threshold/' + self.state.thresholdSelect + '/')).then(() => {
            
            const d = self.props.statsThingDataList.data;

            if (d) {
                let unit = d.unit ? ` (${d.unit})` : ``;

                // Daily
                let labelsdataDaily = [];
                let dataArraydataDaily = [];
                d.daily.forEach((item) => {
                    labelsdataDaily.push(item.ten_min);
                    dataArraydataDaily.push(item.value ? Number(item.value).toFixed(1) : item.value);
                });
                const dataDaily = {
                    labels: labelsdataDaily,
                    datasets: [
                        {
                            label: self.state.pollutantSelect + unit,
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(75,192,192,0.4)',
                            borderColor: 'rgba(75,192,192,1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(75,192,192,1)',
                            pointBackgroundColor: '#fff',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                            pointHoverBorderColor: 'rgba(220,220,220,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: dataArraydataDaily
                        }
                    ]
                };

                // Weekly
                let labelsdataWeekly = [];
                let dataArraydataWeekly = [];
                d.weekly.forEach((item) => {
                    let day = ['Κυριακή', 'Δευτέρα', 'Τρίτη', 'Τετάρτη', 'Πέμπτη', 'Παρασκευή', 'Σάββατο'];
                    labelsdataWeekly.push(day[item.days]);
                    dataArraydataWeekly.push(item.value ? Number(item.value).toFixed(1) : item.value);
                });
                const dataWeekly = {
                    labels: labelsdataWeekly,
                    datasets: [
                        {
                            label: self.state.pollutantSelect + unit,
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(75,192,192,0.4)',
                            borderColor: 'rgba(75,192,192,1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(75,192,192,1)',
                            pointBackgroundColor: '#fff',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                            pointHoverBorderColor: 'rgba(220,220,220,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: dataArraydataWeekly
                        }
                    ]
                };

                // Monthly
                let labelsdataMonthly = [];
                let dataArraydataMonthly = [];
                d.monthly.forEach((item) => {
                    let month = ['', 'Ιανουάριος', 'Φεβρουάριος', 'Μάρτιος', 'Απρίλιος', 'Μάιος', 'Ιούνιος', 'Ιούλιος', 'Αύγουστος', 'Σεπτέμβριος', 'Οκτώβριος', 'Νοέμβριος', 'Δεκέμβριος'];
                    labelsdataMonthly.push(month[item.months]);
                    dataArraydataMonthly.push(item.value ? Number(item.value).toFixed(1) : item.value);
                });
                const dataMonthly = {
                    labels: labelsdataMonthly,
                    datasets: [
                        {
                            label: self.state.pollutantSelect + unit,
                            fill: false,
                            lineTension: 0.1,
                            backgroundColor: 'rgba(75,192,192,0.4)',
                            borderColor: 'rgba(75,192,192,1)',
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: 'rgba(75,192,192,1)',
                            pointBackgroundColor: '#fff',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                            pointHoverBorderColor: 'rgba(220,220,220,1)',
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            data: dataArraydataMonthly
                        }
                    ]
                };

                // threshold
                const threshold = Number(d.count.split(" ")[0]);

                self.setState({
                    loading: self.props.statsThingDataWait,
                    dataDaily: dataDaily,
                    dataWeekly: dataWeekly,
                    dataMonthly: dataMonthly,
                    threshold: threshold
                });
            }
        });

    }

    render() {

        return (
            <div>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="pollutantSelect">Ρύπος</Label>
                            <Input type="select" name="pollutantSelect"
                                id="pollutantSelect" onChange={(e) => {
                                    this.setState({ pollutantSelect: e.target.value })
                                }}>
                                <option />
                                {
                                    this.props.parameters.map((val, idx) => {
                                        return <option value={val.value}>{val.value === 'Benzene' ? 'C6H6' : val.value}</option>
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="fromSelect">Από Ημερομηνία</Label>
                            <Input
                                type="date"
                                name="fromSelect"
                                id="fromSelect"
                                placeholder="date placeholder"
                                onChange={(e) => {
                                    this.setState({ fromSelect: e.target.value })
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="toSelect">Έως Ημερομηνία</Label>
                            <Input
                                type="date"
                                name="toSelect"
                                id="toSelect"
                                placeholder="date placeholder"
                                onChange={(e) => {
                                    this.setState({ toSelect: e.target.value })
                                }}
                                locale={this.state.locale}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="stationSelect">Σταθμός</Label>
                            <Input type="select" name="stationSelect"
                                id="stationSelect" onChange={(e) => {
                                    this.setState({ stationSelect: e.target.value })
                                }}>
                                <option />
                                {
                                    this.state.stations.map((val, idx) => {
                                        return <option value={val.token}>{val.description}</option>
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
					{
                    //<Col>
                    //    <FormGroup>
                    //        <Label for="thresholdSelect">Οριακή Τιμή</Label>
                    //        <Input type="number" name="thresholdSelect"
                    //            id="thresholdSelect" onChange={(e) => {
                    //                this.setState({ thresholdSelect: e.target.value })
                    //            }}>
                    //        </Input>
                    //    </FormGroup>
                    //</Col>
					}
                    <Col>
                        <Button color="primary" style={{ marginTop: '2em', width: '100%' }}
                            onClick={this.fetchData}>Εμφάνιση</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <hr />
                        {
                            this.props.statusStationDataList !== 200 && this.props.statusStationDataList !== '' && <Error status={this.props.statusStationDataList} errorMsg={this.props.statsStatioErrorMsg} />
                        }
                        {
                            this.props.statusThingDataList !== 200 && this.props.statusThingDataList !== '' && <h5>Δεν υπάρχουν δεδομένα για τις συγκεκριμένες επιλογές...</h5>
                        }
                        {this.state.loading ?
                            this.state.startLoading && this.props.statusThingDataList === '' ?
                                <div style={{ height: '100px', width: '100%' }}><Loading /></div> : ''
                            :
                            <div><div style={{ height: '500px', width: '100%', margin: '1em' }}>
                                <h5>Ημερήσια Διακύμανσης της Συγκέντρωσης</h5>
                                <Line
                                    data={this.state.dataDaily}
                                    width={100}
                                    height={50}
                                    options={{
                                        maintainAspectRatio: false
                                    }}
                                />
                            </div>
                                <hr />
                                <div style={{ height: '500px', width: '100%', margin: '1em' }}>
                                    <h5>Εβδομαδιαία Διακύμανσης της Συγκέντρωσης</h5>
                                    <Line
                                        data={this.state.dataWeekly}
                                        width={100}
                                        height={50}
                                        options={{
                                            maintainAspectRatio: false,
                                            scales: {
                                                xAxes: [{
                                                    ticks: {
                                                        autoSkip: false,
                                                        maxRotation: 45,
                                                        minRotation: 45
                                                    }
                                                }]
                                            }
                                        }}
                                    />
                                </div>
                                <hr />
                                <div style={{ height: '500px', width: '100%', margin: '1em' }}>
                                    <h5>Μηνιαία Διακύμανσης της Συγκέντρωσης</h5>
                                    <Bar
                                        data={this.state.dataMonthly}
                                        width={100}
                                        height={50}
                                        options={{
                                            maintainAspectRatio: false
                                        }}
                                    />
                                </div>
                                {this.state.thresholdSelect !== '' && this.state.thresholdSelect !== null ?
                                    <div style={{ paddingTop: '1em' }}>
                                        <hr />
                                        <h5>Αριθμός ωριαίων μέσων τιμών πάνω από την Οριακή Τιμή: {this.state.threshold}</h5>

                                    </div>
                                    :
                                    <React.Fragment></React.Fragment>
                                }
                            </div>
                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
    statusThingDataList: state.list.statsThingData.status,
    statsThingDataList: state.list.statsThingData.data,
    statsThingDataWait: state.list.statsThingData.pending,
    statsThingErrorMsg: state.list.statsThingData.errorMsg,
    statusStationDataList: state.list.statsStationData.status,
    statsStationDataList: state.list.statsStationData.data,
    statsStationDataWait: state.list.statsStationData.pending,
    statsStatioErrorMsg: state.list.statsStationData.errorMsg,
});

StatisticsSection2 = connect(mapStateToProps)(StatisticsSection2);

export default StatisticsSection2;
