import React, { Component } from 'react';
import {
	Button, Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Input, Label, Row, Col
} from 'reactstrap';

import { DateInput } from 'input-fields';
import T from 'modules/i18n';

class CalibrateModal extends Component {

	constructor(props) {
		super(props);
		this.emptyRow = {
			label: '',
			slope: '',
			intercept: '',
			start_time: '',
			end_time: '',
			valid_min: '',
			valid_max: '',
		};
		this.state = {
			values: Object.keys(this.emptyRow).reduce((obj, key) => ({
				...obj,
				[key]: (props.calibration && props.calibration[key]) ? props.calibration[key] : this.emptyRow[key]
			}), {})
		};
	}

	handleChange = (event) => {
		const { name, value } = event.target;
		this.setState({
			values: {
				...this.state.values,
				[name]: value,
			}
		});
	}

	render() {

		const { isOpen, toggle, calibration } = this.props;
		const { values } = this.state;

		return (
			<Modal isOpen={isOpen} className="modal-md">
				<ModalHeader toggle={toggle}><T>calibration</T></ModalHeader>
				<Form onSubmit={(e) => this.props.onSubmit(e, values, calibration ? calibration.id : undefined)}>
					<ModalBody>
						<Row>
							<Col className="py-0">
								<FormGroup>
									<Label>
										<T>label</T>
										<Input name="label" value={values.label} onChange={this.handleChange}/>
									</Label>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col sm={6} className="py-0">
								<FormGroup>
									<Label>
										<T>slope</T>
										<Input type="number" step="0.01" name="slope" value={values.slope} onChange={this.handleChange}/>
									</Label>
								</FormGroup>
							</Col>
							<Col sm={6} className="py-0">
								<FormGroup>
									<Label>
										<T>intercept</T>
										<Input type="number" step="0.01" name="intercept" value={values.intercept} onChange={this.handleChange}/>
									</Label>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col sm={6} className="py-0">
								<FormGroup>
									<Label>
										<T>start_time</T>
										<DateInput name="start_time" value={values.start_time} onChange={this.handleChange}/>
									</Label>
								</FormGroup>
							</Col>
							<Col sm={6} className="py-0">
								<FormGroup>
									<Label>
										<T>end_time</T>
										<DateInput name="end_time" value={values.end_time} onChange={this.handleChange}/>
									</Label>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col sm={6} className="py-0">
								<FormGroup>
									<Label>
										<T>valid_min</T>
										<Input type="number" step="0.01" name="valid_min" value={values.valid_min} onChange={this.handleChange}/>
									</Label>
								</FormGroup>
							</Col>
							<Col sm={6} className="py-0">
								<FormGroup>
									<Label>
										<T>valid_max</T>
										<Input type="number" step="0.01" name="valid_max" value={values.valid_max} onChange={this.handleChange}/>
									</Label>
								</FormGroup>
							</Col>
						</Row>
					</ModalBody>
					<ModalFooter className="text-right">
						<Button color="success" className="mr-2"><T>submit</T></Button>
						<Button color="warning" onClick={toggle}><T>cancel</T></Button>
					</ModalFooter>
				</Form>
			</Modal>
		);
	}
}

export default CalibrateModal;
