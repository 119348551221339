import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	// {
	// 	name: 'login',
	// 	url: StaticRoutes.Login,
	// 	role: roles.GUEST
	// }
];

export default menu;
