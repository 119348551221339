import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'settings',
		icon: 'fa fa-sliders',
		url: StaticRoutes.Settings,
		children: [
			{
				name: 'things',
				icon: 'fa fa-rss',
				url: StaticRoutes.Things,
				role: roles.ADMIN,
			},
			{
				name: 'sensors',
				icon: 'fa fa-microchip',
				url: StaticRoutes.Sensors,
				role: roles.ADMIN,
			},
			{
				name: 'properties',
				icon: 'fa fa-thermometer-three-quarters',
				url: StaticRoutes.Properties,
				role: roles.ADMIN,
			},
			// {
			// 	name: 'calibration',
			// 	icon: 'fa fa-thermometer-three-quarters',
			// 	url: StaticRoutes.Calibration,
			// 	role: roles.ADMIN,
			// },
		]
	},
];

export default menu;
