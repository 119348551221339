import * as roles from 'core/model/roles';
const Settings = '/admin/settings';
const Things = Settings + '/things';
const Sensors = Settings + '/sensors';
const Properties = Settings + '/properties';
const Calibration = Settings + '/calibration';

export const StaticRoutes = {
	Settings,
	Calibration,
	Things,
	Sensors,
	Properties,
};

const routes = [
	{
		url: Settings,
		name: 'settings',
		role: roles.ADMIN,
		to: Things,
		exact: true,
	},
	{
		url: Things,
		name: 'things',
		component: 'Things',
		contextComponent: 'ThingsSidebar',
		role: roles.ADMIN,
	},
	{
		url: Sensors,
		name: 'sensors',
		component: 'ObjectList',
		props: {scope: 'sensor'},
		role: roles.ADMIN,
	},
	{
		url: Properties,
		name: 'properties',
		component: 'ObjectList',
		props: {scope: 'property'},
		role: roles.ADMIN,
	},
	// {
	// 	url: Calibration,
	// 	name: 'calibration',
	// 	component: 'Calibration',
	// 	role: roles.ADMIN,
	// }
];

const combined = {StaticRoutes, routes};

export default combined;
