import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Table, Thead, Tbody, Tr, Td } from 'css-table';
import { deleteData } from 'core/ducks/update';
import { toggleModal } from 'core/ducks/ui/modal';
import Alert from 'core/views/modals/alert';
import EditItem from './editItem';
import T from 'modules/i18n';

class Edit extends Component {

	constructor(props) {
		super(props);
		this.emptyRow = {
			description: '',
			unit: {name: '', symbol: ''},
			sensor: {token: '', label: '', description: '', metadata: '', enc_type: ''},
			property: {token: '', name: '', symbol: '', definition: '', description: ''},
		}
		this.state = {
			data: props.data,
			isNewRowEnabled: false,
		};
		this.actions = bindActionCreators({toggleModal, deleteData}, this.props.dispatch);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.data.length !== this.state.data.length)
			this.props.toggleButton(this.state.data.length !== 0);
	}

	handleDelete = (token) => {
		this.actions.toggleModal(true,
			<Alert
				onConfirm={() => {
					this.actions.deleteData(`datastream/token/${token}`)
						.then(() => {
							const newData = this.state.data.filter(elem => elem.token !== token);
							this.setState({data: [...newData]})
						});
				}}
				toggle={() => this.actions.toggleModal()}
				message="do you wish to continue"
				title="drop confirm"
			/>
		);
	}

	handleNewSubmit = (values) => {
		this.setState({
			data: [...this.state.data, {...values}],
			isNewRowEnabled: false,
		});
	}

	render() {
		const { messages, thing } = this.props;
		const { isNewRowEnabled, data } = this.state;

		return (
			<>
				<Table className="mb-0">
					<Thead>
						<Tr>
							{Object.keys(this.emptyRow).map(field => (
								<Td key={`head_cell_${field}`}><T>{field}</T></Td>
							))}
							<Td></Td>
						</Tr>
					</Thead>
					<Tbody>
						{ data.map((stream, index) => (
							<EditItem key={`row_${stream.token}`} stream={stream} messages={messages} thing={thing} onDelete={this.handleDelete}/>
						))}
						{ isNewRowEnabled &&
							<EditItem
								stream={this.emptyRow}
								messages={messages}
								onDelete={() => this.setState({isNewRowEnabled: false})}
								thing={thing}
								onNewSubmit={this.handleNewSubmit}
							/>
						}
					</Tbody>
				</Table>
				<div className="text-right mr-5">
					<i
						role="link"
						style={{fontSize: '200%'}}
						className="fa fa-plus-circle text-success mr-5"
						onClick={() => this.setState({isNewRowEnabled: true})}
						title={messages.add || 'Add'}
					/>
				</div>
			</>
		);
	}
}

Edit = connect(null)(Edit);

export default Edit;
