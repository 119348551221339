import * as roles from 'core/model/roles';
/**
 * Static routes
 */

// Admin
const Users = '/admin/users';
const UsersList = '/admin/users/list';
const UsersAdd = '/admin/users/add';

export const StaticRoutes = {
	Users,
	UsersList,
	UsersAdd,
};

/**
 * Dynamic routes
 */
const UsersRole =  UsersList + '/role/:role';
const UsersStatus = UsersList + '/status/:status';
const UsersRoleStatus =  UsersList + '/(role)?/:role?/(status)?/:status?';

export const DynamicRoutes = {
	UsersRole,
	UsersStatus,
	UsersRoleStatus,
};

export const routes = [
	{
		url: Users,
		name: 'users',
		to: UsersList,
		exact: true,
	},
	{
		url: UsersRoleStatus,
		name: 'list',
		component: 'Users',
		role: roles.REVIEWER,
		exact: true,
	},
	{
		url: UsersAdd,
		name: 'add',
		component: 'UsersAdd',
		role: roles.ADMIN,
	},
];

const combined = {StaticRoutes, DynamicRoutes, routes};

export default combined;
