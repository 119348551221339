import React, { Component } from 'react';
import { Input } from 'reactstrap';
import { TableContextConsumer } from './table';

class Search extends Component {

	constructor(props) {
		super(props);
		this.state = { pending: false, proc: '' };
		this.handleSearchChange = this.handleSearchChange.bind(this);
	}

	handleSearchChange(event) {
		const { onChange, context } = this.props;
		const { pending, proc } = this.state;
		context.onSearchChange(event);
		if (pending)
			clearTimeout(proc);
		if (typeof onChange === 'function') {
			const this_event = Object.assign({}, event);
			let proc = setTimeout(() => {
				onChange(this_event);
				this.setState({pending: false, proc: ''});
			}, 500);
			this.setState({
				pending: true,
				proc
			});
		}
	}

	render() {
		const {onChange, context, ...others } = this.props;
		return (
			<Input
				{...others}
				type="search"
				onChange={this.handleSearchChange}
				autoComplete="off"
				value={context.query}
			/>
		);
	}
}

Search.displayName = 'Search';

export default props => (
	<TableContextConsumer>
		{context => <Search {...props} context={context} />}
	</TableContextConsumer>
);
