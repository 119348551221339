import { Api } from '../api';
import { checkCookie } from './profile';


// Actions
const REQUESTING_DATA = 'api/list/REQUESTING';
const REFRESHING = 'api/list/REFRESHING';
const FETCHED_DATA = 'api/list/FETCHED';
const FETCHED_FAILED = 'api/list/FAILED';
const SET_PAGE = 'list/SET_PAGE';
const SORT_BY = 'list/SORT_BY';
const RELOADED = 'list/RELOADED';


// Initial state

const internal = {
	pending: true,
	refreshing: false,
	data: {},
	page: 1,
	sortMethod: 'asc',
	needsReload: false,
	status: '',
	errorMsg: ''
};

const initialState = {
	users: { ...internal, sortedBy: 'last_name' },
	template: { ...internal},
	sensor: {...internal},
	property: {...internal},
	workflow: { ...internal, sortedBy: 'workflow' },
	forms: {...internal},
	application: { ...internal, sortedBy: 'created' },
	apply: { ...internal },
	nodes: { ...internal },
	tree: { ...internal },
	graph: { ...internal },
	files: { ...internal },
	fieldlist: { ...internal },

	dashboardData: { ...internal },
	stationStatisticsData: { ...internal },
	stationWeekData: { ...internal },
	stationMonthData: { ...internal },

	stationPM1AvgData: { ...internal },
	stationPM10AvgData: { ...internal },
	stationPM25AvgData: { ...internal },
	stationPM1MonthData: { ...internal },
	stationPM10MonthData: { ...internal },
	stationPM25MonthData: { ...internal },

	statsPropertyData: {...internal },
	statsStationData: {...internal },
	statsThingData: {...internal }
};


// Reducer

export default (state=initialState, action) => {
	let scopeState = state[action.scope] ? state[action.scope] : internal;
	let actionState = {};
	switch (action.type) {
		case REQUESTING_DATA:
			actionState[action.scope] = Object.assign({}, scopeState, {
				pending: action.pending,
				status: action.status
			});
			return Object.assign({}, state, actionState);

		case REFRESHING:
			actionState[action.scope] = Object.assign({}, scopeState, {
				refreshing: action.refreshing,
				status: action.status
			});
			return Object.assign({}, state, actionState);

		case FETCHED_DATA:
			actionState[action.scope] = Object.assign({}, scopeState, {
				pending: action.pending,
				refreshing: action.pending,
				data: action.data,
				status: action.status
			});
			return Object.assign({}, state, actionState);

		case FETCHED_FAILED:
			actionState[action.scope] = Object.assign({}, scopeState, {
				pending: action.pending,
				refreshing: action.pending,
				data: action.data,
				status: action.status,
				errorMsg: action.errorMsg
			});
			return Object.assign({}, state, actionState);

		case SET_PAGE:
			actionState[action.scope] = Object.assign({}, scopeState, {
				page: action.page
			});
			return Object.assign({}, state, actionState);

		case SORT_BY:
			actionState[action.scope] = Object.assign({}, scopeState, {
				sortMethod: scopeState.sortedBy===action.sortedBy
					? (scopeState.sortMethod==='asc' ? 'desc' : 'asc')
					: scopeState.sortMethod,
				sortedBy: action.sortedBy,
				needsReload: action.needsReload
			});
			return Object.assign({}, state, actionState);

		case RELOADED:
			actionState[action.scope] = Object.assign({}, scopeState, {
				needsReload: action.needsReload
			});
			return Object.assign({}, state, actionState);

		default:
			return state;
	}
}


// Action Creators

const requestingData = (scope) => ({
	type: REQUESTING_DATA,
	scope,
	pending: true,
	status: ''
});

const refreshing = (scope) => ({
	type: REFRESHING,
	scope,
	refreshing: true,
	status: ''
});

const fetchedData = (scope, data) => ({
	type: FETCHED_DATA,
	scope,
	data,
	pending: false,
	status: 200
});

const fetchedFailed = (scope, status, errorMsg) => ({
	type: FETCHED_FAILED,
	scope,
	data: {},
	pending: false,
	status,
	errorMsg
});

const setPage = (scope, page) => ({
	type: SET_PAGE,
	page
});

export const sortBy = (scope, field) => ({
	type: SORT_BY,
	sortedBy: field,
	needsReload: true
});

export const reloaded = (scope) => ({
	type: RELOADED,
	needsReload: false
});


// Thunk action Creators

export const requestData = (scope, url=scope, api=true) => (dispatch, getState) => {
	let promise = new Promise((resolve, reject) => {
		dispatch( checkCookie() ).then(() => {
			if (getState().list[scope] && getState().list[scope].data === {}) {
				dispatch(requestingData(scope));
			} else {
				dispatch(refreshing(scope));
			}
			let a = new Api(url, api);
			let status = '';
			let promise = a.Get().then((response) => {
				status = response.status;
				return response.json();
			});
			promise.then((json) => {
				if (status === 200) {
					dispatch(fetchedData(scope, json));
					resolve(json);
				} else {
					dispatch(fetchedFailed(scope, status, json.Error));
					reject(status);
				}
			});
		});
	});

	return promise;
};

export const changePage = (scope, page) => (dispatch) => {
	dispatch(setPage(page));
}


// Selectors

export const pending = (state, scope) => {
	return state.data[scope].pending;
}

export const getData = (state, scope) => {
	return state.list[scope].data.values;
}

export const getInfo = (state, scope) => {
	const {values, ...meta} = state.list[scope].data;
	return meta;
}
