import React, { Component } from 'react';
import {
	ModalHeader, Modal, ModalBody, Button, Form, FormGroup, Label, Input
} from 'reactstrap';
import { connect } from 'react-redux';
import { File } from 'input-fields';

class UploadImage extends Component {

	constructor(props) {
		super(props);

		const hasAlt = props.hasAlt === false ? false : true;
		this.state = hasAlt
			? {
				file: props.values.file || '',
				alt: props.values.alt || '',
				files: {},
			}
			: {
				file: props.values.file || '',
				files: {},
			};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event) {
		const { name, value, type } = event.target;
		this.setState({
			[name]: value
		});
		if (type === 'file')
			this.setState({
				files: event.target.files
			});
	}

	handleSubmit(event) {
		event.preventDefault();
		const { getValues, toggle } = this.props;
		if (typeof getValues === 'function')
			getValues(this.state);
		toggle();
	}

	render() {
		const { modalOpen, isModalOpen, toggle } = this.props;
		const hasAlt = this.props.hasAlt === false ? false : true;

		return (
			<Modal isOpen={modalOpen || isModalOpen} toggle={toggle} className="modal-lg">
				<ModalHeader toggle={toggle}>Upload Image</ModalHeader>
				<ModalBody>
					<Form onSubmit={this.handleSubmit} >
						<FormGroup>
							<Label htmlFor="fileBrowser">Upload Image</Label>
							<File
								id="fileBrowser"
								name="file"
								label="Επιλογή αρχείου"
								accept="image/*"
								value={this.state.file}
								onChange={this.handleChange}
								multiple={false}
							/>
						</FormGroup>
						{ hasAlt &&
							<FormGroup>
								<Input required name="alt" autoComplete="off" placeholder="Alternative Text" value={this.state.alt} onChange={this.handleChange} />
							</FormGroup>
						}
						<FormGroup className="text-right">
							<Button color="success" type="submit">Submit</Button>
						</FormGroup>
					</Form>
				</ModalBody>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	modalOpen: state.ui.modal.modalOpen
});

UploadImage = connect(mapStateToProps)(UploadImage);

export default UploadImage;
