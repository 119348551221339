import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, ButtonGroup, UncontrolledPopover, PopoverBody, Table, Modal, ModalHeader, ModalBody, Card, CardBody, CardTitle } from 'reactstrap';
import 'ol/ol.css'
import 'ol-ext/dist/ol-ext.css'
import Map from 'ol/Map';
import View from 'ol/View';
import { defaults as defaultControls } from 'ol/control';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import PointGeom from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import { Circle as CircleStyle, Fill, Text, Style } from 'ol/style';
import Stroke from 'ol/style/Stroke';
import OverlayPopup from 'ol-ext/overlay/Popup';

import AirqualitylegendInfoControl from '../../components/airqualitylegend/airqualitylegend';

import PopupMessage from '../../components/message/popupmessage';

import { getData } from 'core/ducks/update';
import { requestData } from "core/ducks/list";
import './css/thermiair.css';
import moment from 'moment';

const orderArrayGroup = [
    ['PM10', 'PM2.5', 'O3', 'NO', 'NO2', 'NOx', 'SO2', 'CO', 'Benzene'],
    ['Temperature', 'Rel. Humidit', 'Air Pressure', 'Wind Spd.', 'Rain'],
    // ['Benzene', 'mp-Xylene', 'Styrene', 'Toluene', 'o-Xylene', 'EthylBenzene']
];

const airIndexColors = {
    veryGood: '#37AC56',
    good: '#9BD444',
    medium: '#F1D208',
    poor: '#FFBB01',
    veryPoor: '#FF8C00',
    extremelyPoor: '#ED0F05'
}
class PollutionMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            map: null,
            data: null,
            layers: [],
            aq1Layer: null,
            pm1Layer: null,
            pm25Layer: null,
            pm10Layer: null,
            AQI: false,
            modal: false,
            currentTime: 0,

            messageVisible: false,
            messageTitle: '',
            messageText: ''
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.createLayer = this.createLayer.bind(this);
        this.updateClock = this.updateClock.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(
            getData(`content/item/message/locale/${this.props.locale}`)
        ).then(response => {
            if (!response.content && !response.locale) {
                this.setState({ messageTitle: '', messageText: '', messageVisible: false });
            } else {
                const { content, label, fromdate, todate } = response;
                if (moment(fromdate) <= moment() && moment() <= moment(todate)) {
                    this.setState({ messageTitle: label, messageText: content, messageVisible: true });
                } else {
                    this.setState({ messageTitle: '', messageText: '', messageVisible: false });
                }
            }
        })

        this.props.dispatch(requestData('airquality')).then(() => {

            let t = this;
            let LegendControl = new AirqualitylegendInfoControl();

            let popup = new OverlayPopup({
                popupClass: "default", //"tooltips", "warning" "black" "default", "tips", "shadow",
                closeBox: true,
                onshow: function () { console.log("You opened the box"); },
                onclose: function () { console.log("You close the box"); },
                positioning: 'auto',
                autoPan: {
                    animation: { duration: 250 }
                }
            });

            let map = new Map({
                layers: [
                    new TileLayer({
                        source: new XYZ({
                            attributions: 'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer">ArcGIS</a>',
                            url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                        }),
                    })
                ],
                overlays: [popup],
                controls: defaultControls({ attribution: false }).extend([
                    // new LayerPopup(),
                    // LegendControl
                ]),
                target: 'pollution-map',
                view: new View({
                    center: [2762663.513, 4201151.057],
                    zoom: 9,
                    minZoom: 9,
                    extent: [2534499.239, 4116124.276, 3057328.512, 4314860.550]
                })
            });
            // LegendControl.set(map);


            // Hover info
            let currentFeature;
            const displayFeatureInfo = function (pixel, target) {

                const groups = [[], [], []];

                const feature = target.closest('.ol-control')
                    ? undefined
                    : map.forEachFeatureAtPixel(pixel, function (feature) {
                        return feature;
                    });
                if (feature) {
                    if (feature !== currentFeature) {
                        let content = '<h5><span style="color: ' + feature.get('colorIndex') + ';"><svg class="svg-inline--fa fa-circle fa-w-16" style="width: 12px; height: 12px; margin-right: 5px; " aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path></svg></span>' + (t.props.i18n[feature.get('label')] || feature.get('label')) + '</h5>';

                        orderArrayGroup.forEach((group, index) => {
                            group.forEach((e) => {
                                feature.get('datastreams').forEach(datastream => {
                                    if (datastream.property.symbol === e) {
                                        groups[index].push(datastream);
                                    }
                                });
                            });
                        });

                        groups.forEach(e => {
                            if (e.length > 0) {
                                content += '<div style="display: inline-block; margin-left: 10px; vertical-align: top; width: 130px; margin-right: 20px;">';
                                e.forEach(datastream => {
                                    let color = 'black';

                                    t.props.list.airquality.data.pollutants.forEach(pol => {
                                        if (pol.type === datastream.property.symbol) {
                                            Object.keys(pol.range).forEach(r => {
                                                if (pol.range[r][0] < datastream.value && datastream.value <= pol.range[r][1]) {
                                                    color = airIndexColors[r]
                                                }
                                            })
                                        }
                                    })


                                    content += '<table style="width: 100%; color: ' + color + '"><tr><td style="text-align: left;">' + (t.props.i18n[datastream.property.symbol] || datastream.property.symbol) + '</td><td style="text-align: right;">' + Number(datastream.value).toFixed(1) + ' ' + datastream.unit.symbol + '</td></tr></table><hr style="padding: 0; margin: 0;"/>';
                                });
                                content += '</div>';
                            }
                        });

                        content += '<br><br>' + t.props.i18n['UpdatedOn'] + ': ' + groups[0][0].result_time.slice(0, -6) + '<br>'

                        popup.show(feature.getGeometry().getFirstCoordinate(), content);
                    }
                } else {
                    popup.hide();
                }
                currentFeature = feature;
            };

            map.on('pointermove', function (evt) {
                if (evt.dragging) {
                    console.log(evt)
                    return;
                }
                const pixel = map.getEventPixel(evt.originalEvent);
                displayFeatureInfo(pixel, evt.originalEvent.target);
            });
            map.on('click', function (evt) {
                if (evt.dragging) {
                    console.log(evt)
                    return;
                }
                const pixel = map.getEventPixel(evt.originalEvent);
                displayFeatureInfo(pixel, evt.originalEvent.target);
            });

            this.setData(map);

        });


        this.updateClock();
        this.intervalId = setInterval(this.updateClock, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    createLayer(id, visible, value, type, legend) {
        const t = this;
        let features = [];
        let stations = value;

        stations.forEach(function (station) {


            let levels = [];

            const keysArray = Object.keys(airIndexColors);
            for (let i = 0; i < keysArray.length; i++) {
                const key = keysArray[i];
                const value = airIndexColors[key];
                levels.push({ index: i, key: key, value: value });
            }

            let colorIndex = 0;
            let color = '#37AC56';

            t.props.list.airquality.data.pollutants.forEach(pol => {
                station.datastreams.forEach(datastream => {
                    if (pol.type === datastream.property.symbol) {
                        Object.keys(pol.range).forEach(r => {
                            if (pol.range[r][0] < datastream.value && datastream.value <= pol.range[r][1]) {
                                levels.forEach(l => {
                                    if (l.key === r && l.index > colorIndex) {
                                        colorIndex = l.index;
                                        color = l.value;
                                    }
                                })
                            }
                        })
                    }
                })
            });

            let feature = new Feature({
                geometry: new PointGeom(fromLonLat([Number(station.lon), Number(station.lat)])),
                description: station.description,
                label: station.description,
                value: 10,
                datastreams: station.datastreams,
                colorIndex: color
            });
            features.push(feature);
        });

        let vectorSource = new VectorSource({ features: features });

        let setStyle = (feature) => {

            let measure_date = moment(feature.get('result_time'));
            let now_date = moment();
            let diff = now_date.diff(measure_date, 'h');

            let s = new Fill({ color: [0, 0, 0, 0.5] });
            let f = new Fill({ color: '#000' });

            if (diff > 2) {
                s = new Fill({ color: [160, 160, 160, 0.7] })
            } else if (visible) {
                s = new Fill({ color: feature.get('colorIndex') });;
            } else {
                s = new Fill({ color: [245, 245, 245, 1] });
                ;
            }

            return new Style({
                image: new CircleStyle({
                    fill: s,
                    radius: 14,
                    stroke: new Stroke({
                        color: 'white',
                        width: 2,
                    }),
                }),
                text: new Text({
                    textAlign: 'center',
                    textBaseline: 'middle',
                    font: '14px Calibri,sans-serif',
                    text: '', //feature.get('value').toString(),
                    fill: f,
                    placement: 'point',
                    overflow: 'true'
                })
            });
        };
        return new VectorLayer({
            id: id,
            title: id,
            source: vectorSource,
            style: setStyle,
            baseLayer: true,
            visible: visible,
            legend: legend
        });
    }

    setData(map) {
        this.setState({ refreshing: true });
        this.props.dispatch(requestData('dashboardData', 'data/bbox/180,180,-180,-180,4326')).then(() => {

            let stations = this.props.dashboardDataList;

            const layers = [
                {
                    id: 'AQI',
                    visible: true,
                    value: stations,
                    type: 'PM2.5',
                    legend: null
                }
            ];

            layers.forEach((e, i) => {
                const ly = this.createLayer(e.id, i === 0 ? true : e.visible, e.value, e.type, e.legend);
                map.addLayer(ly);
            });

            this.setState({
                map: map,
                refreshing: false,
                data: this.props.dashboardDataList
            });
        });
    }

    toggleModal() {
        this.setState({ modal: !this.state.modal })
    }

    addObjectIfNotExists(arr, obj) {
        let exists = arr.some(item => {
            return JSON.stringify(item) === JSON.stringify(obj);
        });

        if (!exists) {
            arr.push(obj);
        }
    }

    getObjectByProperty(arr, property, value) {
        return arr.find(item => item[property] === value);
    }

    updateClock() {
        const now = moment();
        const formattedDate = now.format('DD/MM/YYYY HH:mm:ss');
        this.setState({ currentTime: formattedDate });
    };

    render() {

        return (
            <div id="pollution-map" className="map">

                <div className='pollution-map-overview' >
                    <h4>{this.props.i18n['appTitle']}</h4>
                    <div>{this.state.currentTime}</div>
                    <div style={{ marginTop: '0.5em' }}>{this.props.i18n['mapRef']}</div>
                    <div style={{ marginTop: '0.5em' }}>
                        <Button color="primary" style={{ background: '#06163A' }} onClick={this.toggleModal}>{this.props.i18n['Overview']}</Button>
                        <Button id="PollutionLevel" color="primary" style={{ background: '#06163A' }}>{this.props.i18n['PollutionLevel']}</Button>
                        <Button id="ParametersExplanation" color="primary" style={{ background: '#06163A' }}>{this.props.i18n['ParametersExplanation']}</Button>
                    </div>
                </div>

                <UncontrolledPopover
                    placement="bottom"
                    target="PollutionLevel"
                    trigger="legacy"
                >
                    <PopoverBody>
                        <Table striped style={{ fontSize: 'x-small' }}>
                            <thead>
                                <tr>
                                    <th>{this.props.i18n['Pollutant']}</th>
                                    <th style={{ color: airIndexColors.veryGood }}>{this.props.i18n['VeryGood']}</th>
                                    <th style={{ color: airIndexColors.good }}>{this.props.i18n['Good']}</th>
                                    <th style={{ color: airIndexColors.medium }}>{this.props.i18n['Medium']}</th>
                                    <th style={{ color: airIndexColors.poor }}>{this.props.i18n['Poor']}</th>
                                    <th style={{ color: airIndexColors.veryPoor }}>{this.props.i18n['VeryPoor']}</th>
                                    <th style={{ color: airIndexColors.extremelyPoor }}>{this.props.i18n['ExtremelyPoor']}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.list && this.props.list.airquality && !this.props.list.airquality.pending && this.props.list.airquality.data &&
                                    this.props.list.airquality.data.pollutants.map((p, i) =>
                                        <tr key={`pollutant-${i}`}><th scope="row">{p.type}</th>
                                            <td style={{ color: airIndexColors.veryGood }}>{`${p.range.veryGood.join('-')} `}</td>
                                            <td style={{ color: airIndexColors.good }}>{`${p.range.good.join('-')} `}</td>
                                            <td style={{ color: airIndexColors.medium }}>{`${p.range.medium.join('-')} `}</td>
                                            <td style={{ color: airIndexColors.poor }}>{`${p.range.poor.join('-')} `}</td>
                                            <td style={{ color: airIndexColors.veryPoor }}>{`${p.range.veryPoor.join('-')} `}</td>
                                            <td style={{ color: airIndexColors.extremelyPoor }}>{`${p.range.extremelyPoor.join('-')} `}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </PopoverBody>
                </UncontrolledPopover>

                <UncontrolledPopover
                    placement="bottom"
                    target="ParametersExplanation"
                    trigger="legacy"
                >
                    <PopoverBody>
                        <Table striped style={{ fontSize: 'x-small' }}>
                            <tbody>
                                <tr><th scope="row">PM10</th><td>{this.props.i18n['PM10_explanation']}</td></tr>
                                <tr><th scope="row">PM2.5</th><td>{this.props.i18n['PM2.5_explanation']}</td></tr>
                                <tr><th scope="row">O3</th><td>{this.props.i18n['O3_explanation']}</td></tr>
                                <tr><th scope="row">NO</th><td>{this.props.i18n['NO_explanation']}</td></tr>
                                <tr><th scope="row">NO2</th><td>{this.props.i18n['NO2_explanation']}</td></tr>
                                <tr><th scope="row">NOX</th><td>{this.props.i18n['NOX_explanation']}</td></tr>
                                <tr><th scope="row">SO2</th><td>{this.props.i18n['SO2_explanation']}</td></tr>
                                <tr><th scope="row">CO</th><td>{this.props.i18n['CO_explanation']}</td></tr>
                                <tr><th scope="row">C6H6</th><td>{this.props.i18n['Benzene_explanation']}</td></tr>
                            </tbody>
                        </Table>
                    </PopoverBody>
                </UncontrolledPopover>

                <Modal isOpen={this.state.modal} toggle={this.toggleModal} style={{ maxWidth: '850px' }}>
                    <ModalHeader toggle={this.toggleModal} style={{ padding: '0.5rem 0.5rem' }}>{this.props.i18n['Overview']}</ModalHeader>
                    <ModalBody className='pollution-map-overview-modal-content'>
                        {
                            this.state.data && this.state.data.map((station, j) => {
                                const groups = [[], [], []];

                                orderArrayGroup.forEach((group, index) => {
                                    group.forEach((e) => {
                                        station.datastreams.forEach(datastream => {
                                            if (datastream.property.symbol === e) {
                                                groups[index].push(datastream);
                                            }
                                        });
                                    });
                                });

                                let levels = [];

                                const keysArray = Object.keys(airIndexColors);
                                for (let i = 0; i < keysArray.length; i++) {
                                    const key = keysArray[i];
                                    const value = airIndexColors[key];
                                    levels.push({ index: i, key: key, value: value });
                                }

                                let colorIndex = 0;
                                let color = '#37AC56';

                                this.props.list.airquality.data.pollutants.forEach(pol => {
                                    station.datastreams.forEach(datastream => {
                                        if (pol.type === datastream.property.symbol) {
                                            Object.keys(pol.range).forEach(r => {
                                                if (pol.range[r][0] < datastream.value && datastream.value <= pol.range[r][1]) {
                                                    levels.forEach(l => {
                                                        if (l.key === r && l.index > colorIndex) {
                                                            colorIndex = l.index;
                                                            color = l.value;
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    })
                                });

                                return <Card key={`card-${j}`} style={{ width: '12rem', margin: '0.5rem', marginTop: 0, marginBottom: 0 }} >
                                    <CardBody style={{ padding: '0.5rem' }}>
                                        <CardTitle tag="h6">
                                            <span style={{ color: color }}>
                                                <svg class="svg-inline--fa fa-circle fa-w-16" style={{ width: '12px', height: '12px', marginRight: '5px' }} aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path></svg>
                                            </span>
                                            {station.description}
                                        </CardTitle>
                                        {
                                            groups.map(e => e.length > 0 && <div style={{ display: 'inlineBlock', marginLeft: '10px', verticalAlign: 'top', width: '160px', marginRight: '20px' }}>
                                                {
                                                    e.map((datastream, i) => {
                                                        let color = 'black';

                                                        this.props.list.airquality.data.pollutants.forEach(pol => {
                                                            if (pol.type === datastream.property.symbol) {
                                                                Object.keys(pol.range).forEach(r => {
                                                                    if (pol.range[r][0] < datastream.value && datastream.value <= pol.range[r][1]) {
                                                                        color = airIndexColors[r]
                                                                    }
                                                                })
                                                            }
                                                        })

                                                        return <div key={`datastream-${i}`}>
                                                            <table style={{ width: '100%', fontSize: 'smaller', color: color }}>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{this.props.i18n[datastream.property.symbol] || datastream.property.symbol}</td>
                                                                    <td style={{ textAlign: 'right' }}>{Number(datastream.value).toFixed(1)} {datastream.unit.symbol}</td>
                                                                </tr>
                                                            </table>
                                                            <hr style={{ padding: 0, margin: 0 }} />
                                                        </div>
                                                    })
                                                }
                                                <br />
                                            </div>)
                                        }
                                        <div style={{ fontSize: 'x-small' }}>{this.props.i18n['UpdatedOn']}: {groups[0][0].result_time.slice(0, -6)}</div>
                                    </CardBody>
                                </Card>
                            })
                        }
                    </ModalBody>
                </Modal>

                <PopupMessage
                    title={this.state.messageTitle}
                    message={this.state.messageText}
                    visible={this.state.messageVisible}
                    toggle={() => this.setState({ messageVisible: !this.state.messageVisible })}
                />

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
    list: state.list,
    i18n: state.i18n.messages,
    locale: state.i18n.locale,
    dashboardDataList: state.list.dashboardData.data,
    waitDashboardData: state.list.dashboardData.pending,
    stationStatisticsDataList: state.list.stationStatisticsData.data,
    waitStationStatisticsData: state.list.stationStatisticsData.pending,
    stationWeekDataList: state.list.stationWeekData.data,
    waitStationSWeekData: state.list.stationWeekData.pending
});

PollutionMap = connect(mapStateToProps)(injectIntl(PollutionMap));

export default PollutionMap;
