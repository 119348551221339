import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

class T extends Component {

	render() {
		const { locale, pending } = this.props;
		if (pending)
			return null;
		let id = this.props.category ? this.props.category + '.' + this.props.children : this.props.children;
		if (typeof id === 'undefined' || id === '')
			return null;
		if (typeof id === 'object') {
			if (!id)
				return null;
			let span = id[locale] ? <span>{id[locale]}</span> : null;
			return span;
		}
		return this.props.intl.formatMessage({id});
	}
}

const mapStateToProps = (state) => ({
	locale: state.i18n.locale,
	pending: state.i18n.pending,
});

export default connect(mapStateToProps)(injectIntl(T));
