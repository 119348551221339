import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getData, updateData, postData, deleteData } from 'core/ducks/update';
import { Table, Thead, Tbody, Tr, Td } from 'css-table';
import { toggleModal } from 'core/ducks/ui/modal';
import Alert from 'core/views/modals/alert';
import CalibrateModal from './calibrateModal';
import T from 'modules/i18n';

class Calibration extends Component {

	constructor(props) {
		super(props);
		this.fields = ['label', 'slope', 'intercept', 'start_time', 'end_time', 'valid_min', 'valid_max'];
		this.state = {
			isCalibrationModalOpen: false,
			activeStream: null,
			calibration: [],
			activeCalibration: null,
		};
		this.actions = bindActionCreators({toggleModal, deleteData}, this.props.dispatch);
	}

	componentDidMount() {
		this.props.toggleButton(true);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.activeStream !== this.state.activeStream && this.state.activeStream)
			this.fetchData();
	}

	fetchData = () => {
		this.props.dispatch(
			getData(`calibration/datastream/${this.state.activeStream}`)
		).then(calibration => this.setState({calibration}))
		.catch(err => console.warn(err));
	}

	toggleStream = (stream) => {
		const activeStream = stream === this.state.activeStream ? null : stream;
		this.setState({activeStream});
	}

	toggleModal = () => {
		this.setState({isCalibrationModalOpen: false, activeCalibration: null});
	}

	handleSubmit = (e, values, id) => {
		e.preventDefault();
		const data = Object.keys(values)
			.filter(key => values[key].trim() !== '')
			.reduce((obj, key) => ({
				...obj,
				[key]: values[key].trim(),
			}), {});
		if (!id) {
			this.props.dispatch(postData('calibration', {...data, datastream: this.state.activeStream})).then(() => {
				this.toggleModal();
				this.fetchData();
			});
		} else {
			this.props.dispatch(updateData(`calibration/id/${id}`, data)).then(() => {
				this.toggleModal();
				this.fetchData();
			});
		}
	}

	handleDelete = (id) => {
		this.actions.toggleModal(true,
			<Alert
				onConfirm={() => {
					this.actions.deleteData(`calibration/id/${id}`).then(this.fetchData);
				}}
				toggle={() => this.actions.toggleModal()}
				message="do you wish to continue"
				title="drop confirm"
			/>
		);
	}

	render() {

		const { data } = this.props;
		const { isCalibrationModalOpen, activeStream, calibration, activeCalibration } = this.state;
		const { messages } = this.props.i18n || {messages: {}};

		return (
			<Table>
				<Thead>
					<Tr>
						<Td><T>description</T>{' '}(<T>property</T>{' - '}<T>unit</T>)</Td>
						<Td/>
						<Td/>
					</Tr>
				</Thead>
				<Tbody>
					{ data.map(stream => (
						<Tr key={`row_${stream.token}`}>
							<Td>
								<i
									role="link"
									className={activeStream===stream.token ? 'fa fa-eye-slash mr-2' : 'fa fa-eye mr-2'}
									onClick={() => this.toggleStream(stream.token)}
								/>
								{`${stream.description} (${stream.property.symbol || stream.property.name} - ${stream.unit.symbol})`}
							</Td>
							<Td>
							{ activeStream === stream.token &&
								<Table>
									<Thead>
										<Tr>
											{ this.fields.map((field, index) => (
												<Td key={`header_field_${index}`}><T>{field}</T></Td>
											))}
											<Td/>
										</Tr>
									</Thead>
									<Tbody>
										{calibration.map(row => (
											<Tr key={`row_${row.id}`}>
												{ this.fields.map((field, index) => (
													<Td key={`body_field_${row.id}_${index}`}>{row[field]}</Td>
												))}
												<Td>
													<i
														role="link"
														className="fa fa-pencil text-success mr-2"
														title={messages.edit || 'Edit'}
														onClick={() => this.setState({isCalibrationModalOpen: true, activeCalibration: row})}
													/>
													<i
														role="link"
														className="fa fa-trash-o text-warning mr-2"
														title={messages.delete || 'Delete'}
														onClick={() => this.handleDelete(row.id)}
													/>
												</Td>
											</Tr>
										))}
										<Tr>
											{ this.fields.map((field, index) => (
												<Td key={`empty_field_${index}`} className="pt-0"/>
											))}
											<Td className="text-right pt-0">
												<i
													role="link"
													style={{fontSize: '130%'}}
													className="fa fa-plus-circle mr-2"
													title={messages.add || 'Add'}
													onClick={() => this.setState({isCalibrationModalOpen: true})}
												/>
											</Td>
										</Tr>
									</Tbody>
								</Table>
							}
							</Td>
							<Td/>
						</Tr>
					))}
				</Tbody>
				{ isCalibrationModalOpen &&
					<CalibrateModal
						isOpen={isCalibrationModalOpen}
						toggle={this.toggleModal}
						calibration={activeCalibration}
						stream={activeStream}
						onSubmit={this.handleSubmit}
					/>
				}
			</Table>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
});

Calibration = connect(mapStateToProps)(Calibration);

export default Calibration;
