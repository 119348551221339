import React, { Component } from 'react';
import ReactLoading from 'react-loading-components';

class Loading extends Component {
	render() {
		return (
			<div style={{position: 'absolute', left: '50%', top: '50%'}}>
				<ReactLoading type="puff" witdh={100} height={100} fill="#20a8d8"/>
			</div>
		);
	}
}

export default Loading;
