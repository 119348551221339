import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody, CardText, Row, Col } from 'reactstrap';

import { StaticRoutes, DynamicRoutes } from '../model/routes';
import { buildPath } from 'core/model/lib/urlTools';
import { Dashboard as Template, SecureContent } from 'core/components';
import * as roles from 'core/model/roles';
import T from 'modules/i18n';

class Dashboard extends Component {

	render() {
		const { stats } = this.props;

		if (this.props.role === roles.GUEST || !stats.users)
			return null;

		return (
			<Template>
				<SecureContent role={roles.EDITOR}>
					<Card>
						<CardHeader>
							<Link to={StaticRoutes.Users} className="no-decoration">
								<i className="icon-people"></i> { stats.users.total } <T>users</T>
							</Link>
						</CardHeader>
						<CardBody className="text-white bg-info">
							<Row>
								<Col xs="6" lg="6">
									<Link to={buildPath(DynamicRoutes.UsersStatus, ['active'])}>
										<CardText className="text-light text-lowercase"><big>{stats.users.status.active}</big> <T>active_s</T></CardText>
									</Link>
									<Link to={buildPath(DynamicRoutes.UsersStatus, ['inactive'])}>
										<CardText className="text-light text-lowercase"><big>{stats.users.status.inactive}</big> <T>inactive_s</T></CardText>
									</Link>
								</Col>
								<Col xs="6" lg="6">
									{ Object.keys(stats.users.role).map((role) => {
										let number = stats.users.role[role];
										return (
											<Link key={`link_${role}`} to={buildPath(DynamicRoutes.UsersRole, [role])}>
												<CardText className="text-light text-lowercase"><big>{number}</big> <T>{role}</T></CardText>
											</Link>
										);
									})}
								</Col>
							</Row>
						</CardBody>
					</Card>
				</SecureContent>
			</Template>
		);
	}

}

const mapStateToProps = (state) => ({
	role: state.profile.user.role,
	stats: state.update.response,
});

Dashboard = connect(mapStateToProps)(Dashboard);

export default Dashboard;
