import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Table, Form, Card, CardBody, FormGroup, Label, Input, Button, Badge } from 'reactstrap';

import { requestData } from 'core/ducks/list';
import { updateData, deleteData, postData } from 'core/ducks/update';
import T from 'modules/i18n';
import { Loading } from 'core/components';
import { toggleModal } from 'core/ducks/ui/modal';
import Alert from 'core/views/modals/alert';

class Alerts extends Component {

	constructor(props) {
		super(props);
		this.initialState = {
			selected: '',
			values: {recipients: '', threshold: ''},
		}
		this.state = {
			...this.initialState,
			showNewEntryForm: false
		};
		this.actions = bindActionCreators({toggleModal, deleteData}, this.props.dispatch);

		this.handleItemEdit = this.handleItemEdit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleFormSumit = this.handleFormSumit.bind(this);
	}

	componentDidMount() {
		this.requestData();
	}

	requestData() {
		this.props.dispatch( requestData('alert') );
	}

	handleItemEdit(id) {
		const values = this.props.list.alert.data[id];
		this.setState({
			selected: id,
			values,
			showNewEntryForm: false,
		});
	}

	handleInputChange(event) {
		const { name, value } = event.target;
		this.setState({
			values: {...this.state.values, [name]: value}
		});
	}

	handleFormSumit(event) {
		event.preventDefault();
		const { dispatch } = this.props;
		if (this.state.selected === '') {
			dispatch( postData('alert', this.state.values) ).then(() => {this.requestData()});
		} else {
			dispatch( updateData(`alert/id/${this.state.selected}`, this.state.values) ).then(() => {this.requestData()});
		}
		this.setState({
			...this.initialState,
			showNewEntryForm: false
		});
	}

	render() {
		if (!this.props.list.alert || this.props.list.alert.pending)
			return <Loading/>;

		const alerts = this.props.list.alert.data;
		const { messages } = this.props.i18n || {messages: {}};

		const form = (
			<Row className={this.state.showNewEntryForm ? 'border p-4 m-0' : 'p-0 m-0'}>
				<Col md="8" lg="6" xl="5" className="p-0 m-0">
					<Card>
						<CardBody>
							<FormGroup row>
								<Label md="3" htmlFor="recipients_input" className="p-0"><T>recipients</T>:</Label>
								<Col md="9" className="p-0">
									<Input required id="recipients_input" value={this.state.values.recipients} name="recipients" onChange={this.handleInputChange}/>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label md="3" htmlFor="threshold_input" className="p-0"><T>threshold</T>:</Label>
								<Col md="9" className="p-0">
									<Input required id="threshold_input" type="number" value={this.state.values.threshold} name="threshold" onChange={this.handleInputChange}/>
								</Col>
							</FormGroup>
						</CardBody>
					</Card>
				</Col>
				<Col>
					<Button type="submit" className="d-block m-1"><T>submit</T></Button>
					<Button type="button" className="d-block m-1" color="warning" onClick={() => {this.setState({...this.initialState, showNewEntryForm: false})}}><T>cancel</T></Button>
				</Col>
			</Row>
		);

		return (
			<Row>
				<Col>
					<Form onSubmit={this.handleFormSumit}>
						<Table responsive striped className="border">
							<thead>
								<tr>
									<th/>
									<th><T>recipients</T></th>
									<th><T>threshold</T> AQI</th>
									<th><T>inactive alert</T></th>
								</tr>
							</thead>
							<tbody>
								{Object.keys(alerts).map(id => (
									<React.Fragment key={`row_${id}`}>
										<tr>
											<td>
												<i
													className="fa fa-trash-o"
													role="button"
													onClick={index => this.actions.toggleModal(true,
														<Alert
															toggle={this.actions.toggleModal}
															title="drop confirm"
															message="do you wish to continue"
															onConfirm={() => {
																this.actions.deleteData(`alert/id/${id}`);
																this.requestData();
															}}
														/>
													)}
													title={messages.drop || 'drop'} />
												<i className="fa fa-edit" role="button" onClick={() => {this.handleItemEdit(id)}} title={messages.edit || 'edit'} />
											</td>
											<td>{alerts[id].recipients}</td>
											<td>{alerts[id].threshold}</td>
											<td><Badge color={alerts[id].active ? 'warning' : 'success'}>{alerts[id].active ? <i className="fa fa-times"/> : <i className="fa fa-check"/>}</Badge></td>
										</tr>
										{ this.state.selected === id &&
											<tr>
												<td colSpan="3">{form}</td>
											</tr>
										}
									</React.Fragment>
								))}
							</tbody>
						</Table>
						{ this.state.showNewEntryForm ?
							form
							:
							<Row>
								<Col className="text-right">
									<Button type="button" color="primary" onClick={() => this.setState({...this.initialState, showNewEntryForm: true})}><T>add</T></Button>
								</Col>
							</Row>
						}
					</Form>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	list: state.list,
	i18n: state.i18n,
});

Alerts = connect(mapStateToProps)(Alerts);

export default Alerts;
