import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
	Col, Form, FormGroup, Label, Card, CardHeader, CardBody, Button, Input,
} from 'reactstrap';

import { getData, postData, updateData} from 'core/ducks/update';
import { initContext, setContext } from 'core/ducks/context';
import { Editor } from 'editor';
import T from 'modules/i18n';

class MessageEdit extends Component {

	constructor(props) {
		super(props);
		this.state = {
			content: '',
			label: '',
			fromDate: '',
			toDate: '',
			locale: props.locales[0],
			selected: '424df357-14f3-43d1-9d85-3a23fde75911',
			method: null,
			forceUpdate: false,
		};
	}

	componentDidMount() {
		this.props.dispatch(initContext({...this.state, set: this.setContext}));
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.locale !== this.state.locale)
			this.fetchData();
		if (prevState.selected !== this.state.selected)
			this.fetchData();
	}

	setContext = (newContext) => {
		this.setState({...newContext});
		this.props.dispatch(setContext({...newContext}));
	}

	fetchData = () => {
		if (this.state.selected) {
			this.props.dispatch(
				getData(`content/item/${this.state.selected}/locale/${this.state.locale}`)
			).then(response => {
				if (!response.content && !response.locale) {
					this.setState({content: '', label: '', fromDate: '', toDate: '', method: 'post'});
				} else {
					const { content, label, fromdate, todate } = response
					console.log(fromdate)
					this.setState({content, label, fromDate: fromdate, toDate: todate, method: 'put'})
				}
			});
		} else {
			this.setState({content: '', label: '', fromDate: '', toDate: '', method: null});
		}
	}

	handleContentSubmit = (e) => {
		e.preventDefault();
		const { selected, content, label, method, locale, fromDate, toDate } = this.state;
		if (method === 'post') {
			this.props.dispatch (
				postData('content', {item: selected, content, label, locale})
			);
		} else {
			this.props.dispatch(
				updateData(`content/item/${this.state.selected}/locale/${this.state.locale}`, {content, label, fromDate, toDate})
			);
		}
	}

	handlePublish = () => {
		this.props.dispatch(
			postData('publish')
		);
	}

	render() {
		const { content, label, fromDate, toDate, locale } = this.state;
		const { messages } = this.props.i18n || {messages: {}};

		return (
			<React.Fragment>
				<Card>
					<CardHeader><T>message</T></CardHeader>
					<CardBody>
						<Form onSubmit={this.handleContentSubmit}>
							<FormGroup tag="fieldset" className="border p-4" disabled={!this.state.selected}>
								<FormGroup row>
									<Label htmlFor="locale_selector" sm="2"><T>select locale</T></Label>
									<Col sm="10">
										<Input type="select" value={locale} onChange={(e) => this.setState({locale: e.target.value})}>
											{this.props.locales.map(loc => (
												<option value={loc} key={`locale_option_${loc}`}>{messages[loc] || loc}</option>
											))}
										</Input>
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label htmlFor="label_input" sm="2"><T>label</T></Label>
									<Col sm="10">
										<Input required type="text" minLength="3" maxLength="55" value={label} onChange={e => this.setState({label: e.target.value})}/>
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label htmlFor="label_input" sm="2"><T>fromDate</T></Label>
									<Col sm="10">
										<Input required type="date" value={fromDate} onChange={e => this.setState({fromDate: e.target.value})}/>
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label htmlFor="label_input" sm="2"><T>toDate</T></Label>
									<Col sm="10">
										<Input required type="date" value={toDate} onChange={e => this.setState({toDate: e.target.value})}/>
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label htmlFor="front_text_input" sm="2"><T>message</T></Label>
									<Col sm="10">
										<Editor
											id="front_text_input"
											value={content}
											locale={this.props.i18n.locale}
											onChange={(content) => this.setState({content})}
											disabled={!this.state.selected}
										/>
									</Col>
								</FormGroup>
								<div className="text-right">
									<Button color="success" className="mr-2"><T>submit</T></Button>
								</div>
							</FormGroup>
						</Form>
					</CardBody>
				</Card>
			</React.Fragment>
		);
	}

}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
	locales: state.ui.locales,
});

MessageEdit = connect(mapStateToProps)(MessageEdit);

export default MessageEdit;
