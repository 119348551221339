import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Container,
    Row,
    Col,
    CardGroup,
    Card,
    CardBody,
    TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import { requestData } from 'core/ducks/list';
import { Loading } from 'core/components';
import StatisticsSection1 from './statisticsSection1';
import StatisticsSection2 from './statisticsSection2';
import classnames from 'classnames';

import './css/thermiair.css';

const orderArray = ['PM10', 'PM2.5', 'O3', 'NO', 'NO2', 'NOx', 'SO2', 'CO', 'Benzene', 'Temperature', 'Rel. Humidit', 'Air Pressure', 'Wind Spd.', 'Rain', 'mp-Xylene', 'Styrene', 'Toluene', 'o-Xylene', 'EthylBenzene', 'mp_Xylene', 'O3_360']
const showPollutants = ['PM10', 'PM2.5', 'O3', 'NO', 'NO2', 'NOX', 'SO2', 'CO', 'Benzene'];
class Statistics extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            refreshing: false,
            activeTab: '1',
            parameters: []
        };

        this.tabs = [{ id: '1', title: 'Στατιστικά για όλους τους σταθμούς' }, {
            id: '2',
            title: 'Στατιστικά για ένα σταθμό'
        }];

        this.setData();
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    setData() {
        this.setState({ refreshing: true });
        this.props.dispatch(requestData('dashboardData', 'data/bbox/180,180,-180,-180,4326')).then(() => {

            const arr = [];

            this.props.dashboardDataList.forEach(e => {
                e.datastreams.forEach(j => {
                    this.addObjectIfNotExists(arr, {
                        key: j.property.symbol,
                        value: j.property.symbol,
                        label: j.property.symbol
                    })
                })
            });

            const indexMap = new Map();
            orderArray.forEach((element, index) => {
                indexMap.set(element, index);
            });
            arr.sort((a, b) => {
                const indexA = indexMap.get(a.value);
                const indexB = indexMap.get(b.value);

                // If indices are available for both elements, compare them
                if (indexA !== undefined && indexB !== undefined) {
                    return indexA - indexB;
                }

                // If one or both indices are undefined, maintain their relative order
                return orderArray.indexOf(a.value) - orderArray.indexOf(b.value);
            });

            this.setState({
                refreshing: false,
                parameters: arr.filter(obj1 => showPollutants.some(obj2 => obj1.value === obj2 ))
            });
        });
    }

    addObjectIfNotExists(arr, obj) {
        let exists = arr.some(item => {
            return JSON.stringify(item) === JSON.stringify(obj);
        });

        if (!exists) {
            arr.push(obj);
        }
    }

    getObjectByProperty(arr, property, value) {
        return arr.find(item => item[property] === value);
    }

    render() {

        return (
            <div className="">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="12">
                            <CardGroup>
                                <Card>
                                    <CardBody className="text-center">
                                        {
                                            this.props.waitDashboardData ?
                                                <Loading /> :
                                                <React.Fragment>
                                                    <Nav tabs>
                                                        {this.tabs.map((item) =>
                                                            <NavItem>
                                                                <NavLink
                                                                    className={classnames({ active: this.state.activeTab === item.id })}
                                                                    onClick={() => {
                                                                        this.toggle(item.id);
                                                                    }}
                                                                >
                                                                    <b>{item.title}</b>
                                                                </NavLink>
                                                            </NavItem>
                                                        )}
                                                    </Nav>
                                                    <TabContent activeTab={this.state.activeTab}>
                                                        <TabPane tabId="1">
                                                            <StatisticsSection1 parameters={this.state.parameters} />
                                                        </TabPane>
                                                        <TabPane tabId="2">
                                                            <StatisticsSection2 parameters={this.state.parameters} />
                                                        </TabPane>
                                                    </TabContent>
                                                </React.Fragment>
                                        }
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
    dashboardDataList: state.list.dashboardData.data,
    waitDashboardData: state.list.dashboardData.pending
});

Statistics = connect(mapStateToProps)(Statistics);

export default Statistics;
