import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'users',
		url: StaticRoutes.Users,
		icon: 'icon-people',
		role: roles.ADMIN,
		children: [
			{
				name: 'list',
				icon: 'icon-list',
				url: StaticRoutes.UsersList
			},
			{
				name: 'add',
				icon: 'icon-plus',
				url: StaticRoutes.UsersAdd,
				role: roles.ADMIN
			}
		]
	},
	{
		name: 'users',
		url: StaticRoutes.UsersList,
		icon: 'icon-people',
		role: [roles.EDITOR, roles.REVIEWER]
	},
];

export default menu;
