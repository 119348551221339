import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, CardGroup, Card, CardBody, FormGroup, Label, Input } from 'reactstrap';
import { Link } from 'react-router-dom';

import { StaticRoutes } from '../../model/routes';
import T from 'modules/i18n';
import AirQualityIndex from '../../components/airqualityindex/airqualityindex'
import { requestData } from 'core/ducks/list'
import { Loading } from 'core/components'

import './css/thermiair.css'

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: 'O3',
            refreshing: true,
            data: null,
            parameters: []
        };
        this.chooseTypeHandle = this.chooseTypeHandle.bind(this);
        this.setData();
        this.refreshingData()
    }

    setData() {
        this.setState({ refreshing: true });
        this.props.dispatch(requestData('dashboardData', 'data/bbox/180,180,-180,-180,4326')).then(() => {

            const arr = [];
            try {
                this.props.dashboardDataList.forEach(e => {
                    e.datastreams.forEach(j => {
                        this.addObjectIfNotExists(arr, {
                            key: j.property.symbol,
                            value: j.property.symbol,
                            label: j.property.symbol
                        })
                    })
                });
                this.setState({
                    refreshing: false,
                    data: this.props.dashboardDataList,
                    parameters: arr
                });
            } catch (err) { }
        });
    }

    refreshingData() {
        let t = this;
        try {
            setInterval(async () => {
                t.setData()
            }, 300000);
        } catch (e) {
            console.log(e);
        }
    }

    chooseTypeHandle(e) {
        this.setState({
            type: e.target.value
        },
            function () {
                this.setData()
            });
    }

    addObjectIfNotExists(arr, obj) {
        let exists = arr.some(item => {
            return JSON.stringify(item) === JSON.stringify(obj);
        });

        if (!exists) {
            arr.push(obj);
        }
    }

    getObjectByProperty(arr, property, value) {
        return arr.find(item => item[property] === value);
    }

    render() {

        const groups = [
            ['creteAirHrakleio', ['Περιφερειακή Ενότητα Ηρακλείου']],
            ['creteAirChania', ['Περιφερειακή Ενότητα Χανίων']],
            ['creteAirLasithi', ['Περιφερειακή Ενότητα Λασιθίου']],
            ['creteAirRethimno', ['Περιφερειακή Ενότητα Ρεθύμνης']]
        ];

        let idx = 0;

        return (
            <div className="app-container flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="12">
                            <CardGroup>
                                <Card>
                                    <CardBody className="text-center">
                                        <div>
                                            <Row className="justify-content-center">
                                                <Col md={6}>
                                                    <FormGroup row>
                                                        <Label for="typeSelect" md={5}
                                                            size="lg"><h3>{this.state.type === 'AQI' ? <T>thermiairAirQualityIndex</T> : <T>thermiairIndex</T>}
                                                            </h3></Label>
                                                        <Col md={4}>
                                                            <Input type="select" name="select"
                                                                id="typeSelect"
                                                                onChange={this.chooseTypeHandle}
                                                                value={this.state.type}>
                                                                {
                                                                    // <option key="AQI" value={'AQI'}>AQI</option>
                                                                }
                                                                {
                                                                    this.state.parameters.map(e => <option key={`${e.key}-${Math.random()}`} value={e.value}>{e.label}</option>)
                                                                }
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            {
                                                (this.state.type === 'AQI') && <div>
                                                    <Row className="justify-content-center">
                                                        <Col
                                                            className="thermiair-dashboard-class-1"><T>thermiairAirQualityIndexClass1</T>: 0-10</Col>
                                                        <Col
                                                            className="thermiair-dashboard-class-2"><T>thermiairAirQualityIndexClass2</T>: 10-20</Col>
                                                        <Col
                                                            className="thermiair-dashboard-class-3"><T>thermiairAirQualityIndexClass3</T>: 20-25</Col>
                                                        <Col
                                                            className="thermiair-dashboard-class-4"><T>thermiairAirQualityIndexClass4</T>: 25-50</Col>
                                                        <Col
                                                            className="thermiair-dashboard-class-5"><T>thermiairAirQualityIndexClass5</T>: 50+</Col>
                                                    </Row>
                                                    <Row className="justify-content-center font-italic">
                                                        <Col><T>scale_comment</T></Col>
                                                    </Row>
                                                </div>
                                            }
                                        </div>
                                        <hr />
                                        {
                                            !this.state.refreshing ?
                                                <React.Fragment>

                                                    <div className="thermiair-dashboard-div">
                                                        <Row className="justify-content-center">
                                                            {this.state.data.map((item) =>
                                                                <Col sm="auto" key={`${item.location.village}-${Math.random()}`}>
                                                                    <Link to={{
                                                                        pathname: StaticRoutes.PollutionVariation,
                                                                        data: item
                                                                    }}>
                                                                        {
                                                                            this.getObjectByProperty(item.datastreams, 'label', this.state.type === 'AQI' ? 'PM2.5' : this.state.type) && <AirQualityIndex id={item.label}
                                                                                title={item.description}
                                                                                timestamp={this.getObjectByProperty(item.datastreams, 'label', this.state.type === 'AQI' ? 'PM2.5' : this.state.type).result_time}
                                                                                type={this.getObjectByProperty(item.datastreams, 'label', this.state.type === 'AQI' ? 'PM2.5' : this.state.type).property.symbol}
                                                                                value={this.getObjectByProperty(item.datastreams, 'label', this.state.type === 'AQI' ? 'PM2.5' : this.state.type).value}
                                                                                unit={this.getObjectByProperty(item.datastreams, 'label', this.state.type === 'AQI' ? 'PM2.5' : this.state.type).unit.symbol}
                                                                                fixed={this.state.type === 'AQI' ? 0 : 2}
                                                                            />
                                                                        }

                                                                    </Link>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    </div>


                                                    {
                                                        //     groups.map((section) =>
                                                        //     <div className="thermiair-dashboard-div" key={`${section[0]}-${Math.random()}`}>
                                                        //         <h3><T>{section[0]}</T></h3>
                                                        //         <Row className="justify-content-center">
                                                        //             {this.state.data.map((item) =>
                                                        //                 section[1].includes(item.location.village) ?
                                                        //                     <Col sm="auto" key={`${item.location.village}-${Math.random()}`}>
                                                        //                         <Link to={{
                                                        //                             pathname: StaticRoutes.PollutionVariation,
                                                        //                             data: item
                                                        //                         }}>
                                                        //                             {
                                                        //                                 this.getObjectByProperty(item.datastreams, 'label', this.state.type === 'AQI' ? 'PM2.5' : this.state.type) && <AirQualityIndex id={item.label}
                                                        //                                     title={item.description}
                                                        //                                     timestamp={this.getObjectByProperty(item.datastreams, 'label', this.state.type === 'AQI' ? 'PM2.5' : this.state.type).result_time}
                                                        //                                     type={this.getObjectByProperty(item.datastreams, 'label', this.state.type === 'AQI' ? 'PM2.5' : this.state.type).property.symbol}
                                                        //                                     value={this.getObjectByProperty(item.datastreams, 'label', this.state.type === 'AQI' ? 'PM2.5' : this.state.type).value}
                                                        //                                     unit={this.getObjectByProperty(item.datastreams, 'label', this.state.type === 'AQI' ? 'PM2.5' : this.state.type).unit.symbol}
                                                        //                                     fixed={this.state.type === 'AQI' ? 0 : 2}
                                                        //                                 />
                                                        //                             }

                                                        //                         </Link>
                                                        //                     </Col>
                                                        //                     : ''
                                                        //             )}
                                                        //         </Row>
                                                        //         <hr />
                                                        //     </div>
                                                        // )
                                                    }


                                                </React.Fragment>
                                                : <Loading />
                                        }

                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
    dashboardDataList: state.list.dashboardData.data,
    waitDashboardData: state.list.dashboardData.pending
});

Dashboard = connect(mapStateToProps)(Dashboard);

export default Dashboard;
