import React, { Component } from 'react';
import {
	Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Col, Button,
	} from 'reactstrap';

import T from 'modules/i18n';

class ObjectModal extends Component {

	constructor(props) {
		super(props);
		this.intialValues = props.fields.reduce((obj, field) => ({
			...obj,
			[field]: props.data && props.data[field] ? props.data[field] : '',
		}), {});
		this.state = {
			values: {...this.intialValues},
		};
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.onSubmit(this.state.values);
	}

	handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({
			values: {
				...this.state.values,
				[name]: value,
			}
		});
	}

	render() {
		const { isOpen, toggle, scope } = this.props;
		const { values } = this.state;

		return (
			<Modal isOpen={isOpen} className="modal-md">
				<ModalHeader toggle={toggle}><T>{scope}</T></ModalHeader>
				<ModalBody>
					<Form onSubmit={this.handleSubmit}>
						{ Object.keys(values).map(field => (
							<FormGroup key={`formgroup_${field}`} row>
								<Label sm="6" className="py-0"><T>{field}</T></Label>
								<Col className="py-0">
									<Input name={field} value={values[field]} onChange={this.handleChange}/>
								</Col>
							</FormGroup>
						))}
						<div className="text-right">
							<Button color="success" className="mr-2"><T>submit</T></Button>
							<Button color="warning" className="mr-2" onClick={toggle}><T>cancel</T></Button>
						</div>
					</Form>
				</ModalBody>
			</Modal>
		);
	}
}

export default ObjectModal;
