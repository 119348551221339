import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label } from 'reactstrap';

import { Table, Thead, Tbody, Tr, Td } from 'css-table';
import { getData, postData, updateData, deleteData } from 'core/ducks/update';
import { toggleModal } from 'core/ducks/ui/modal';
import Alert from 'core/views/modals/alert';
import T from 'modules/i18n';

class Aggregation extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			streams: props.data,
			aggregation: [],
			label: '',
			isModalOpen: false,
			modalAction: null,
			ready: false,
		};
		this.actions = bindActionCreators({toggleModal, deleteData}, this.props.dispatch);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.streams.length !== this.state.streams.length)
			this.props.toggleButton(this.state.streams.length === 0);
	}

	fetchData = () => {
		this.setState({ready: false, streams: this.props.data});
		this.props.dispatch(
			getData(`aggregation/thing/${this.props.thing}`)
		).then(response => {
			let streams = this.state.streams;
			const data = response.map(elem => {
				const datastreams = [];
				elem.datastreams.forEach(token => {
					const index = streams.findIndex((e) => e.token === token);
					datastreams.push(streams[index]);
					streams = [...streams.slice(0, index), ...streams.slice(index + 1)]
				});
				return {...elem, datastreams};
			});
			this.setState({data, streams, ready: true});
		}).catch(err => console.warn(err));
	}

	handleAggregation = (event) => {
		const { value, checked } = event.target;
		const { aggregation } = this.state;
		if (checked) {
			this.setState({
				aggregation: [...aggregation, value],
			});
		} else {
			const index = aggregation.indexOf(value);
			this.setState({
				aggregation: [
					...aggregation.slice(0, index),
					...aggregation.slice(index + 1),
				],
			});
		}
	}

	handleSubmit = () => {
		const { label, aggregation } = this.state;
		this.props.dispatch(
			postData('aggregation', {label, datastream: aggregation})
		).then(() => {
			this.closeModal();
			this.fetchData();
		});
	}

	closeModal = () => {
		this.setState({
			aggregation: [],
			label: '',
			isModalOpen: false,
			modalAction: null,
		});
	}

	handleEdit = (token) => {
		const { label } = this.state;
		this.props.dispatch(
			updateData(`aggregation/token/${token}`, {label})
		).then(() => {
			this.closeModal();
			this.fetchData();
		});
	}

	handleDelete = (token) => {
		this.actions.toggleModal(true,
			<Alert
				onConfirm={() => {
					this.actions.deleteData(`aggregation/token/${token}`)
						.then(this.fetchData);
				}}
				toggle={this.actions.toggleModal}
				message="do you wish to continue"
				title="drop confirm"
			/>
		);
	}

	render() {

		const { data, streams, aggregation, isModalOpen, label, ready } = this.state;
		const { messages } = this.props.i18n || {messages: {}};
		const fields = ['description', 'unit', 'sensor', 'property'];

		return (
			<Table>
				<Thead>
					<Tr>
						<Td>Aggregation</Td>
						{fields.map(field => (
							<Td key={`head_field_${field}`}><T>{field}</T></Td>
						))}
					</Tr>
				</Thead>
				{ ready &&
					<Tbody>
						{ data.map(elem => (
							<React.Fragment key={`row_${elem.token}`}>
								<Tr>
									<Td>
										<i
											role="link"
											className="fa fa-pencil text-success mr-2"
											title={messages.edit || 'edit'}
											onClick={() => this.setState({label: elem.label, isModalOpen: true, modalAction: () => this.handleEdit(elem.token)})}
										/>
										<i
											role="link"
											className="fa fa-trash-o text-warning mr-2"
											title={messages.delete || 'Delete'}
											onClick={() => this.handleDelete(elem.token)}
										/>
										{elem.label}
									</Td>
								</Tr>
								{ elem.datastreams.map(stream => (
									<Tr key={`cell_${stream.token}`}>
										<Td/>
										{fields.map(field => (
											<Td key={`body_${field}_${stream.token}`}>
												{field === 'description' ? stream.description : (stream[field].symbol || stream[field].sensor || stream[field].name)}
											</Td>
										))}
									</Tr>
								))}
							</React.Fragment>
						))}
						{ streams.length !== 0 &&
							<>
								<Tr><Td><b>[<T>without</T>]</b></Td></Tr>
								{ streams.map(stream => (
									<Tr key={`cell_${stream.token}`}>
										<Td>
											<Input
												type="checkbox"
												className="mx-auto"
												value={stream.token}
												checked={aggregation.includes(stream.token)}
												onChange={this.handleAggregation}
											/>
										</Td>
										{fields.map(field => (
											<Td key={`body_${field}_${stream.token}`}>
												{field === 'description' ? stream.description : (stream[field].symbol || stream[field].sensor || stream[field].name)}
											</Td>
										))}
									</Tr>
								))}
								<Tr><Td>
									<Button
										color="success"
										disabled={aggregation.length===0}
										onClick={() => this.setState({isModalOpen: true, modalAction: this.handleSubmit})}
									>
										Aggregate
									</Button>
								</Td></Tr>
							</>
						}
					</Tbody>
				}
				{ isModalOpen &&
					<Modal isOpen={isModalOpen} className="modal-md">
						<ModalHeader toggle={this.closeModal}>Aggregation</ModalHeader>
						<ModalBody>
							<Form onSubmit={(e) => {
								e.preventDefault();
								this.state.modalAction();
							}}>
								<FormGroup>
									<Label htmlFor="label_input">
										<T>label</T>
									</Label>
									<Input id="label_input" required value={label} onChange={(e) => this.setState({label: e.target.value})}/>
								</FormGroup>
								<div className="text-right">
									<Button color="success" className="mr-2"><T>submit</T></Button>
									<Button color="warning" className="mr-2" onClick={this.closeModal}><T>cancel</T></Button>
								</div>
							</Form>
						</ModalBody>
					</Modal>
				}
			</Table>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
});

Aggregation = connect(mapStateToProps)(Aggregation);

export default Aggregation;