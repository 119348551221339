import React, { Component } from 'react';

import '../style/style.scss';

class Table extends Component {

	render() {

		const className = this.props.className ? 'table ' + this.props.className : 'table';

		return (
			<div className="css-table">
				<div className={className}>
					{this.props.children}
				</div>
			</div>
		);
	}
}

export default Table;
