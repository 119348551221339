import { combineReducers } from 'redux';

import * as reducers from 'core/ducks';
import { reducers as moduleReducers } from 'modules';
import * as uiReducers from 'core/ducks/ui';
import config from '../package.json';

const { multilingual, locales, modules } = config;

const appReducer = combineReducers({
	...reducers,
	...moduleReducers,
	ui: combineReducers({
		...uiReducers,
		multilingual: (state=multilingual) => state,
		locales: (state=locales) => state,
	}),
	modules: (state=modules) => state,
});

const rootReducer = (state, action) => {
	const newState = action.type === 'api/LOGGED_OUT'
		? {i18n: {...state.i18n}, ui: {...state.ui}, modules: [...state.modules]}
		: {...state};

	return appReducer(newState, action);
}

export default rootReducer;
