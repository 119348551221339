import pathToRegexp from 'path-to-regexp';
// import { routes as coreRoutes } from '../routes';
// import * as moduleRoutes from 'modules/routes';
// import moduleRoutes from 'modules/routes';

// const routes = {...coreRoutes, ...moduleRoutes};

export const getBaseUrl = () => {
	const href = window.location.href;
	let parts = href.split('/');
	return parts[0] + '//' + parts[2];
};

/**
 * Find a route by its path e.g. /Dashboard
 *
 * @export
 * @param {string} path - the route path
 * @returns the route properties
 */
export function getRoute(path, routes) {
	const url = matchRoute(path, routes);

	let route = routes.find(elem => elem.url === url);

	return route;
}

/**
 * Matches the given path to an existing route and returns the route or null
 * if no match is found
 *
 * @export
 * @param {any} path - the route path to match
 * @returns the route that matched the given path or null if no match is found
 */
export function matchRoute(path, routes) {
	for (let route of routes) {
		let re = pathToRegexp(route.url);
		if (re.test(path)) {
			return route.url;
		}
	}

	return null;
}

export function getParameters(path, routes) {
	let route = matchRoute(path, routes);
	let keys = [];
	let re = pathToRegexp(route, keys);
	let matches = re.exec(path);
	let params = {};
	keys.forEach((entry, index) => {
		params[entry.name] = matches[index+1];
	});
	return params;
}

/**
 * Build a path given a route and optional parameters
 *
 * @export
 * @param {string} path - The route name
 * @param {string[]|object} params - Optional parameters to bind
 */
export function buildPath(path, params) {
	let result = path || '/';
	let re = /(:[^/()]+)\([^/)]+\)[?]{0,1}/g;
	result = result.replace(re, '$1');

	if (params) {
		if (Array.isArray(params)) {
			re = /:\w+[?]{0,1}/i;
			for (const value of params) {
				result = result.replace(re, encodeURI(value));
			}
		} else if (params instanceof Object) {
			Object.keys(params).forEach(key => {
				re = `:${key}`;
				re = new RegExp(re, 'i');
				if (params[key])
					result = result.replace(re, encodeURI(params[key]));
			});
			re = /\/\([a-z]*\)\?\/:[a-z]*/g;
			result = result.replace(re, '');
		} else {
			let toPath = pathToRegexp.compile(path);
			result = toPath(params);
		}
	}

	re = /\/[^/]+\/:[^/]+\?/g;
	result = result.replace(re, '');

	re = /\(([^/)]+)\)[?]{0,1}/g;
	result = result.replace(re, '$1');

	return result;
}
