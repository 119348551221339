import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	InputGroup, InputGroupAddon, Input, Button, Card, CardHeader, CardBody, CardFooter
} from 'reactstrap';

import { requestData } from 'core/ducks/list';
import { updateData, postData, deleteData } from 'core/ducks/update';
import { pushNotification } from 'core/ducks/notifications';
import { toggleModal } from 'core/ducks/ui/modal';
import Alert from 'core/views/modals/alert';
import { Table, Thead, Tbody, Tr, Td } from 'css-table';
import { Pagination } from 'table';
import { ObjectModal } from '../modals';
import T from 'modules/i18n';

class ObjectList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			page: '',
			query: '',
			isModalOpen: false,
			modalData: null,
		};
		this.actions = bindActionCreators({toggleModal, deleteData, pushNotification}, this.props.dispatch);
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData = () => {
		const { query, page } = this.state;
		const url = [this.props.scope];
		if (page !== '')
			url.push(`page/${page}`);
		if (query !== '') url.push(`query/${query}`);
		this.props.dispatch(
			requestData(url[0], url.join('/'))
		);
	}

	handlePageChange = (page) => {
		if (page !== this.state.page) {
			this.setState({page}, () => this.fetchData());
		}
	}

	handleQueryChange = (event) => {
		const { name, value } = event.target;
		this.setState({
			[name]: value,
			page: '',
		});
	}

	closeModal = () => {
		this.setState({
			isModalOpen: false,
			modalData: null,
		});
	}

	handleSubmit = (values) => {
		const { scope } = this.props;
		if (this.state.modalData) {
			this.props.dispatch(updateData(`${scope}/token/${this.state.modalData.token}`, values)).then(() => {
				this.closeModal();
				this.fetchData();
			});
		} else {
			this.props.dispatch(postData(`${scope}`, values)).then(() => {
				this.closeModal();
				this.fetchData();
			});
		}
	}

	handleDelete = (token) => {
		this.actions.toggleModal(true,
			<Alert
				onConfirm={() => {
					this.actions.deleteData(`${this.props.scope}/token/${token}`, false)
						.then(() => {
							this.fetchData();
							this.actions.pushNotification({body: 'data deleted', type: 'success'});
						})
						.catch(status => {
							console.log(status);
							if (status === 403) {
								this.actions.pushNotification({body: 'could not be removed', type: 'warning'})
							} else {
								this.actions.pushNotification({body: 'action denied', type: 'warning'})
							}
						});
				}}
				toggle={this.actions.toggleModal}
				message="do you wish to continue"
				title="drop confirm"
			/>
		);
	}

	render() {
		const { messages } = this.props.i18n || {messages: {}};
		const { scope } = this.props;
		const { query, isModalOpen, modalData } = this.state;
		const fields = scope === 'sensor'
			? ['label', 'description', 'metadata', 'enc_type']
			: ['name', 'symbol', 'definition', 'description'];

		return (
			<Card className="admin-sensor">
				<CardHeader><T>{scope}</T></CardHeader>
				<CardBody>
					<div>
						<InputGroup>
							<Input type="search" placeholder={`${messages.search || 'search'}...`} name="query" value={query} onChange={this.handleQueryChange}/>
							<InputGroupAddon addonType="append">
								<Button color="success" onClick={this.fetchData}><i className="fa fa-chevron-right"/></Button>
							</InputGroupAddon>
						</InputGroup>
					</div>

					<Table>
						<Thead>
							<Tr>
								<Td/>
								{ fields.map(field => (
									<Td key={`head_field_${field}`}><T>{field}</T></Td>
								))}
							</Tr>
						</Thead>
						{(!this.props[scope].pending && this.props[scope].status === 200) &&
							<Tbody className={this.props[scope].refreshing ? 'semi-transparent' : undefined}>
								{this.props[scope].data.values.map((row, index) => (
									<Tr key={`row_${row.token}`}>
										<Td className="text-center">
											<i
												className="fa fa-pencil mr-2 text-primary"
												title={messages.edit || 'Edit'}
												role="link"
												onClick={() => this.setState({isModalOpen: true, modalData: row})}
											/>
											<i
												className="fa fa-trash mr-2 text-danger"
												title={messages.delete || 'Delete'}
												role="link"
												onClick={() => this.handleDelete(row.token)}
											/>
										</Td>
										{ fields.map(field => (
											<Td key={`body_field_${field}_${index}`}>{row[field]}</Td>
										))}
									</Tr>
								))}
							</Tbody>
						}
					</Table>
					<Pagination
						className="mx-auto"
						page={this.props[scope].data.page || 1}
						total={this.props[scope].data.total_pages || 1}
						onClick={this.handlePageChange}
					/>
				</CardBody>
				<CardFooter className="text-right">
					<Button color="success" onClick={() => this.setState({isModalOpen: true})}><T>add</T></Button>
				</CardFooter>
				{ isModalOpen &&
					<ObjectModal
						isOpen={isModalOpen}
						toggle={this.closeModal}
						scope={scope}
						onSubmit={this.handleSubmit}
						data={modalData}
						fields={fields}
					/>
				}
			</Card>
		);
	}
}

const mapStateToProps = (state) => ({
	sensor: state.list.sensor,
	property: state.list.property,
	i18n: state.i18n,
});

ObjectList = connect(mapStateToProps)(ObjectList);

export default ObjectList;
