import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getData } from 'core/ducks/update';
import { ErrorPage } from 'core/views/pages';
import T from 'modules/i18n';

class Content extends Component {
	constructor(props) {
		super(props);
		this.state = {
			label: '',
			html: '',
			httpStatus: 200,
		}
	}

	fetchContent = (content, locale) => {
		this.props.dispatch(
			getData(`content/item/${content}/locale/${locale}`)
		).then(html => this.setState({ html }))
			.catch(httpStatus => this.setState({ httpStatus }));
	}

	componentDidMount() {
		const { params } = this.props.match;
		if (params.content) {
			this.fetchContent(params.content, this.props.locale);
			const { label } = this.props.custom_menu[`/content/${params.content}`];
			this.setState({ label });
		}
	}

	componentDidUpdate(prevProps) {
		const { params } = this.props.match;
		if (prevProps.match.params.content !== this.props.match.params.content || prevProps.locale !== this.props.locale) {
			if (this.props.match.params.content) {
				this.fetchContent(this.props.match.params.content, this.props.locale);
				const { label } = this.props.custom_menu[`/content/${params.content}`];
				this.setState({ label });
			} else {
				this.setState({ html: '' });
			}
		}
	}

	render() {
		if (this.state.httpStatus !== 200)
			return (<ErrorPage status={this.state.httpStatus} />);
		return (
			<>
				<h3 className="pt-0 mt-0 text-info"><T>{this.state.label}</T></h3>
				<div className="border-bottom border-info mb-3" />
				<div className="text-justify" dangerouslySetInnerHTML={{ __html: this.state.html }} />
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	locale: state.i18n.locale,
	custom_menu: state.ui.settings.values.custom_menu,
});

Content = connect(mapStateToProps)(Content);

export default Content;
