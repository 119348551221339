// Actions
const TOGGLE_LANG_DROPDOWN = 'menu/TOGGLE_LANG_DROPDOWN';

// Reducer
export const initialState = {
	langDropdownOpen: false,
};

export default (state, action) => {
	switch (action.type) {
		case TOGGLE_LANG_DROPDOWN:
			return {
				...state,
				langDropdownOpen: !state.langDropdownOpen
			}

		default:
	  		return state;
  	}
};

// Action creators
export const toggleLangDropdown = () => ({
	type: TOGGLE_LANG_DROPDOWN,
});
