export const ADMIN = 'admin';
export const GUEST = 'guest';
export const EDITOR = 'editor';
export const AUTHORIZED = 'authorized';
export const REVIEWER = 'reviewer';

export const hierarchy = [
	ADMIN,
	EDITOR,
	REVIEWER,
	AUTHORIZED,
	GUEST
];
