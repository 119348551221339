import React, { Component } from 'react';
import { connect } from 'react-redux';
import { defaults as defaultControls } from "ol/control";
import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import TileWMS from 'ol/source/TileWMS';
import OSM from "ol/source/OSM";
import XYZ from 'ol/source/XYZ';
import View from "ol/View";
import 'ol-ext/dist/ol-ext.css';
import LayerPopup from 'ol-ext/control/LayerPopup';
import moment from 'moment';
import { requestData } from 'core/ducks/list';
import LegendInfoControl from '../../components/legend/legend';
import TimeSlider from '../../components/timeslider/timeslider';

import './css/thermiair.css';

class Cams extends Component {

    constructor(props) {
        super(props);
        this.state = {
            map: null,
            min: moment().startOf('hour').valueOf(),
            max: moment().add(2, 'days').startOf('hour').valueOf(),
            step: 3600000,
        };
    }

    componentDidMount() {
        this.props.dispatch(requestData('forecasts'));
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.list.forecasts && ((prevProps.list.forecasts.pending && !this.props.list.forecasts.pending) || (prevProps.list.forecasts.refreshing && !this.props.list.forecasts.refreshing)))
            || (prevProps.i18n !== this.props.i18n)
        ) {

            try {
                this.state.map.setTarget(null);
            } catch (error) {
                
            }
            const { forecasts } = this.props.list;
            let LegendControl = new LegendInfoControl();
            let osmLayer = new TileLayer({
                source: new XYZ({
                    attributions: 'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer">ArcGIS</a>',
                    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                }),
            });
            let layers = forecasts.data.layers.map(layer => new TileLayer({
                id: layer.id,
                title: this.props.i18n[layer.title] || layer.title,
                baseLayer: layer.baseLayer,
                visible: layer.visible,
                source: new TileWMS({
                    url: layer.url,
                    params: {
                        LAYERS: layer.layer,
                        token: layer.token,
                    },
                }),
                legend: layer.legend,
                opacity: layer.opacity,
            }));

            let map_view = new View({
                projection: 'EPSG:4326',
                center: forecasts.data.map.center,
                zoom: forecasts.data.map.zoom,
                minZoom: forecasts.data.map.minZoom,
                extent: forecasts.data.map.extent
            });
            let map = new Map({
                layers: [osmLayer, ...layers],
                controls: defaultControls({ attribution: false }).extend([
                    new LayerPopup(),
                    LegendControl
                ]),
                target: 'cams_map',
                view: map_view
            });
            LegendControl.set(map);

            this.setState({
                map: map,
            });
        }
        
    }

    render() {

        return (
            <React.Fragment>
                <div id="cams_map" className="map" />
                <div className="cams_legend_units">µg/m³</div>
                <div style={{ position: "absolute", bottom: "180px", right: "0.5em", maxWidth: '300px',background: "rgba(255,255,255,0.7)", borderRadius: "4px", padding: "5px" }}>{this.props.i18n['camsRef']} <a href="https://atmosphere.copernicus.eu/" target="_blank" rel="atmosphere.copernicus.eu">https://atmosphere.copernicus.eu</a></div>
                <TimeSlider
                    min={this.state.min}
                    max={this.state.max}
                    step={this.state.step}
                    map={this.state.map}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    list: state.list,
    i18n: state.i18n.messages,
});

Cams = connect(mapStateToProps)(Cams);

export default Cams;
