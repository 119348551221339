import React, { Component } from 'react';
import { Button, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';

const defaultTitle = "Houston, we have a problem!";
const defaultSubtitle = "The page you are looking for is temporarily unavailable.";
const title = {
	404: "Oops! You're lost.",
	500: "Houston, we have a problem!",
};

const subtitle = {
	404: "The page you are looking for was not found.",
	500: "The page you are looking for is temporarily unavailable.",
};

class ErrorPage extends Component {

	render() {

		const { status } = this.props;

		return (
			<div className="app flex-row align-items-center">
				<Container>
					<Row className="justify-content-center">
						<Col md="6">
							<span className="clearfix">
								<h1 className="float-left display-3 mr-4">{status}</h1>
								<h4 className="pt-3">{title[status] || defaultTitle}</h4>
								<p className="text-muted float-left">{subtitle[status] || defaultSubtitle}</p>
							</span>
							<InputGroup className="input-prepend">
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
										<i className="fa fa-search"></i>
									</InputGroupText>
								</InputGroupAddon>
								<Input size="16" type="text" placeholder="What are you looking for?" />
								<InputGroupAddon addonType="append">
									<Button color="info">Search</Button>
								</InputGroupAddon>
							</InputGroup>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default ErrorPage;
