import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Modal, ModalHeader, ModalBody, Form, FormGroup, Input, Button, InputGroup, InputGroupAddon
} from 'reactstrap';

import { requestData } from 'core/ducks/list';
import { Table, Thead, Tbody, Tr, Td } from 'css-table';
import { Pagination } from 'table';
import T from 'modules/i18n';

class ParameterModal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			values: {
				...props.stream[props.scope],
			},
			query: '',
			page: '',
		};
	}

	componentDidMount() {
		if (this.props.scope !== 'unit')
			this.fetchData();
	}

	handleQueryChange = (event) => {
		const { name, value } = event.target;
		this.setState({
			[name]: value,
			page: '',
		});
	}

	handleChange = (event) => {
		const { name, value } = event.target;
		this.setState({
			values: {
				...this.state.values,
				[name]: value,
			}
		});
	}

	handleRadioChange = (event, values) => {
		const { checked } = event.target;
		if (checked)
			this.setState({
				values: {...values}
			});
	}

	fetchData = () => {
		const { query, page, values } = this.state;
		const url = [this.props.scope];
		if (page !== '') {
			url.push(`page/${page}`);
		} else {
			url.push(`findpage/${values.token}`)
		}
		if (query !== '') url.push(`query/${query}`);
		this.props.dispatch(
			requestData(url[0], url.join('/'))
		);
	}

	handlePageChange = (page) => {
		if (page !== this.state.page) {
			this.setState({page}, () => this.fetchData());
		}
	}

	SpecificForm = (props) => {
		const { values, query } = this.state;
		const { messages } = props.i18n || {messages: {}};
		switch (props.scope) {
			case 'unit':
				return (
					<Form onSubmit={(e) => props.handleAccept(e, values)}>
						<FormGroup>
							<Input placeholder={messages.label || 'Label'} name="name" value={values.name} onChange={this.handleChange}/>
						</FormGroup>
						<FormGroup>
							<Input placeholder={messages.symbol || 'Symbol'} name="symbol" value={values.symbol} onChange={this.handleChange}/>
						</FormGroup>
						<div className="text-right">
							<Button color="success" className="mr-2"><T>accept</T></Button>
							<Button color="warning" className="mr-2" onClick={props.toggle}><T>cancel</T></Button>
						</div>
					</Form>
				);

			case 'sensor':
			case 'property':
				const fields = props.scope === 'sensor'
					? ['label', 'description', 'metadata', 'enc_type']
					: ['name', 'symbol', 'definition', 'description'];
				return (
					<>
						<div>
							<InputGroup>
								<Input type="search" placeholder={`${messages.search || 'search'}...`} name="query" value={query} onChange={this.handleQueryChange}/>
								<InputGroupAddon addonType="append">
									<Button color="success" onClick={this.fetchData}><i className="fa fa-chevron-right"/></Button>
								</InputGroupAddon>
							</InputGroup>
						</div>

						<Table>
							<Thead>
								<Tr>
									<Td/>
									{ fields.map(field => (
										<Td key={`head_field_${field}`}><T>{field}</T></Td>
									))}
								</Tr>
							</Thead>
							{(!props[props.scope].pending && props[props.scope].status === 200) &&
								<Tbody className={props[props.scope].refreshing ? 'semi-transparent' : undefined}>
									{props[props.scope].data.values.map((row, index) => (
										<Tr key={`row_${row.token}`}>
											<Td>
												<Input
													type="radio"
													className="d-block mx-auto"
													value={values.token}
													checked={values.token === row.token}
													onChange={(e) => this.handleRadioChange(e, row)}
												/>
											</Td>
											{ fields.map(field => (
												<Td key={`body_field_${field}_${index}`}>{row[field]}</Td>
											))}
										</Tr>
									))}
								</Tbody>
							}
						</Table>
						<Pagination
							className="mx-auto"
							page={props[props.scope].data.page || 1}
							total={props[props.scope].data.total_pages || 1}
							onClick={this.handlePageChange}
						/>
						<Form onSubmit={(e) => props.handleAccept(e, values)}>
							<div className="text-right">
								<Button color="success" className="mr-2"><T>accept</T></Button>
								<Button color="warning" className="mr-2" onClick={props.toggle}><T>cancel</T></Button>
							</div>
						</Form>
					</>
				);

			default:
				return null;
		}
	}


	render() {
		const { isOpen, toggle, scope } = this.props;
		const { SpecificForm } = this;

		return (
			<Modal isOpen={isOpen} className={scope === 'unit' ? 'modal-md' : 'modal-lg'} scrollable>
				<ModalHeader toggle={toggle}><T>{scope}</T></ModalHeader>
				<ModalBody>
					<SpecificForm {...this.props}>
					</SpecificForm>
				</ModalBody>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	sensor: state.list.sensor,
	property: state.list.property,
	i18n: state.i18n,
});

ParameterModal = connect(mapStateToProps)(ParameterModal);

export default ParameterModal;
