import React, { Component } from 'react';
import { FormGroup, Input, Label, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

class CheckBox extends Component {

	constructor(props) {
		super(props);
		this.state = {
			value: (props.value && props.value !== '') ? props.value.split(';') : [],
			combinedValue: props.value || '',
		};
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { value } = this.props;
		if ( prevProps.value !==  value)
			this.setState({
				value: (value && value !== '') ? value.split(';') : [],
				combinedValue: value || '',
			});
	}

	handleChange(event) {
		const { target } = event;
		let value = this.state.value;
		value = target.checked ?  [...value, target.value] : value.filter(elem => elem!==target.value);
		const combinedValue = value.join(';');
		this.setState({
			value,
			combinedValue,
		});
		event.target.value = combinedValue;
		if (typeof this.props.onChange === 'function')
			this.props.onChange(event);
	}

	render() {
		const {required, name, readOnly} = this.props;

		let input_field = this.props.options && Object.values(this.props.options).map((value) => {
			return (
				<FormGroup key={`form_group_${value}`} className="mb-1" check >
					<Label check >
						<Input
							type="checkbox"
							checked={this.state.value.includes(value)}
							value={value}
							onChange={this.handleChange}
							name={name}
							required={required && this.state.combinedValue === ''}
							disabled={readOnly}
						/>
						{` ${value}`}
					</Label>
				</FormGroup>
			);
		});

		let length = input_field.length;
		let rows = Math.floor(length/3);
		let remainder = length%3;
		let col = [];
		let previous = -1;
		for (let i=0; i < 3; i++) {
			col[i] = input_field.filter((elem, index) => {
				return (index > previous && index <= previous + rows + remainder);
			});
			previous += rows + remainder;
			remainder = Math.max(0, remainder - 1);
		}

		return (
			<Row>
				{col.map((elem, index) => {
					return (
						<Col key={`column_${index}`}>
							{elem}
						</Col>
					);
				})}
			</Row>
		);
	}

}

CheckBox.propTypes = {
	value: PropTypes.string.isRequired,
	required: PropTypes.bool.isRequired,
	name: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	readOnly: PropTypes.bool,
};

export default CheckBox;
