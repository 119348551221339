// Actions
const TOGGLE_MODAL = 'TOGGLE_MODAL';
const MODAL_SUBMIT_ENABLED = 'MODAL_SUBMIT_ENABLED';

// Reducer
const initialState = {
	modalOpen: false,
	modalSubmitEnabled: false,
	generalModal: false,
	component: ''
};

export default (state = initialState, action) => {
	switch (action.type) {

		case TOGGLE_MODAL:
			return {
				...state,
				modalOpen: !state.modalOpen,
				generalModal: action.generalModal,
				component: action.component
			}

		case MODAL_SUBMIT_ENABLED:
			return {
				...state,
				modalSubmitEnabled: action.modalSubmitEnabled
			}

		default:
	  		return state;
  	}
};

// Action creators

export const toggleModal = (generalModal=false, component='') => ({
	type: TOGGLE_MODAL,
	generalModal,
	component
});

const enableModalSubmit = () => ({
	type: MODAL_SUBMIT_ENABLED,
	modalSubmitEnabled: true,
});

const disableModalSubmit = () => ({
	type: MODAL_SUBMIT_ENABLED,
	modalSubmitEnabled: false,
});

export const toggleModalSubmit = (cond) => (dispatch) => {
	if (cond) {
		dispatch(enableModalSubmit());
	} else {
		dispatch(disableModalSubmit());
	}
};