import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';
import { uniqueId } from 'lodash';
import { TableContextConsumer } from './table';

class CustomFilter extends Component {

	constructor(props) {
		super(props);
		this.customInput = React.Children.only(props.children);
	}

	componentDidMount() {
		this.scope = uniqueId('filter_');
		let defaultValue = this.props.value || '';
		this.props.context.registerFilter(this.scope, defaultValue);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.scope = uniqueId('filter_');
			let defaultValue = this.props.value || '';
			this.props.context.registerFilter(this.scope, defaultValue);
		}
	}

	render() {

		const { context } = this.props;

		const CustomInput = this.customInput.type;

		return (
			<FormGroup>
				<CustomInput
					{...this.customInput.props}
					value={context.filterValue[this.scope]}
					onChange={(event) => {
						context.onFilterChange(this.scope, event);
						if (this.props.onChange)
							this.props.onChange(event);
					}}
				/>
			</FormGroup>
		);
	}
}

CustomFilter.displayName = 'CustomFilter';

export default props => (
	<TableContextConsumer>
		{context => <CustomFilter {...props} context={context} />}
	</TableContextConsumer>
);
