import React, { Component } from 'react';
import { FormattedDate } from 'react-intl';
import {
	Modal,
	ModalHeader,
	ModalBody,
	Row,
	Col
} from 'reactstrap';
import PropTypes from 'prop-types';

import T from 'modules/i18n';

class View extends Component {

	render() {

		const { toggle, title, layout } = this.props;
		const noOfCol = this.props.columns ? this.props.columns : 1;
		let data = [];
		let number = Math.ceil(Object.keys(this.props.data).length / noOfCol);
		Object.keys(this.props.data).forEach((field, index) => {
			let column = Math.floor(index / number);
			if (!data[column])
				data.push({});
			data[column][field] = this.props.data[field];
		});

		return (
			<Modal isOpen={true} className={noOfCol===1 ? 'modal-md' : 'modal-xl'}>
				<ModalHeader toggle={toggle}><T>{title}</T></ModalHeader>
				<ModalBody>
					<Row>
						{data.map((column, index) => {
							return (
								<Col key={`column_${index}`} className="border-right">
									{Object.keys(column).map((field) => {
										let value = column[field];
										if (layout && layout[field]) {
											if (layout[field].type === 'translatable') {
												value = (<T>{value}</T>);
											} else if (layout[field].type === 'date') {
												value = (
													<FormattedDate
														value={value}
														year='numeric'
														month='long'
														day='2-digit'
													/>
												);
											} else if (layout[field].type === 'boolean') {
												value = value ? <i className="fa fa-check px-2"/> : <i className="fa fa-times px-2"/>;
											} else if (layout[field].type === 'email' || field.toLowerCase() === 'email') {
												value = (<a href={`mailto:${value}`}>{value}</a>);
											}
										} else if (field.toLowerCase() === 'email') {
											value = (<a href={`mailto:${value}`}>{value}</a>);
										}
										return (
											<Row key={field}>
												<Col className="bg-light font-weight-bold with-border-bottom" sm="12" md="4">
													{ typeof this.props.translateFields != 'undefined' && this.props.translateFields === false ?
														<span>{field}</span>
														:
														<T>{field}</T>
													}
												</Col>
												<Col className="with-border-bottom fixed-height py-1" sm="12" md="8">{value}</Col>
											</Row>
										);
									})}
								</Col>
							);
						})}
					</Row>
				</ModalBody>
			</Modal>
		);
	}
}

View.propTypes = {
	toggle: PropTypes.func.isRequired,
	title: PropTypes.string,
	data: PropTypes.object.isRequired,
	layout: PropTypes.object,
	translateFields: PropTypes.bool,
	columns: PropTypes.number
};

export default View;
