import constants from 'modules/constants';
import * as role from 'core/model/roles';

const { roles } = constants;
const customStyle = roles ? roles.style : {};
const customList = roles ? roles.list : [];

export const rolesStyle = {
	[role.ADMIN]: 'danger',
	[role.EDITOR]: 'warning',
	[role.REVIEWER]: 'secondary',
	[role.AUTHORIZED]: 'success',
	...customStyle,
};

export const rolesList = [
	role.ADMIN,
	role.EDITOR,
	role.REVIEWER,
	role.AUTHORIZED,
	...customList,
];
