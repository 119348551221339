import React, { Component } from 'react';

class Checkbox extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isChecked: (props.allChecked || props.list.indexOf(props.data.index) !== -1) ? true : false,
		};
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.allChecked !== this.props.allChecked)
			this.setState({isChecked: this.props.allChecked});

		if (!this.props.allChecked && prevProps.list !== this.props.list)
			this.setState({isChecked: this.props.list.indexOf(this.props.data.index) !== -1});
	}

	handleChange(event) {
		const { checked } = event.target;
		this.setState({
			isChecked: checked,
		});
		if (typeof this.props.onChange === 'function')
			this.props.onChange({index: this.props.data.index, checked});
	}

	render() {

		const { isChecked } = this.state;
		const { onChange, list, allChecked, ...otherProps } = this.props;

		return (
			<input type="checkbox" checked={isChecked} onChange={this.handleChange} {...otherProps}/>
		);
	}
}

Checkbox.displayName = 'Checkbox';

export default Checkbox;
