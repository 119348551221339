import { StaticRoutes } from './model/routes';
import * as roles from './model/roles';

const menu = [
	{
		name: 'settings',
		url: StaticRoutes.Settings,
		icon: 'fa fa-sliders',
		role: roles.ADMIN,
		children: [
			{
				name: 'general',
				url: StaticRoutes.GeneralSettings,
				icon: 'icon-wrench',
			},
		],
	},
];

export default menu;
