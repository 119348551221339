import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Button } from 'reactstrap';

import { requestData } from 'core/ducks/list';
import { updateData } from 'core/ducks/update';
import { Table, Title, Tbody, Thead, Tr, Pagination } from 'table';
import { Loading } from 'core/components';
import T from 'modules/i18n';

class Calibration extends Component {

	constructor(props) {
		super(props);
		this.fields = ['datastream', 'unit', 'location', 'sensor', 'property', 'slope', 'intercept'];
		this.state = {
			sort: 'datastream',
			sort_method: 'asc',
			query: '',
			page: 1,
			refreshing: false,
			data: {},
			selected: {},
			isModalOpen: false,
		};

		this.handleRowClick = this.handleRowClick.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}

	componentDidMount() {
		this.requestData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.list.calibration && ((prevProps.list.calibration.pending && !this.props.list.calibration.pending) || (prevProps.list.calibration.refreshing && !this.props.list.calibration.refreshing)))
			this.reduceData(this.props.list.calibration.data.values);
	}

	requestData() {
		const url = this.createUrl();
		this.setState({refreshing: true}, () => {
			this.props.dispatch(requestData('calibration', url));
		});
	}

	createUrl() {
		let { query, sort, sort_method } = this.state;
		let url = `calibration/page/${this.state.page}/sort/${sort}/sort_method/${sort_method}`;
		query = (query !== '') ? '/query/' + query : query;
		url += query;

		return url;
	}

	reduceData(data) {
		const reduced = Object.keys(data).map(id => id).reduce((obj, id) => ({
			...obj,
			[id]: {
				datastream: data[id].datastream.description,
				unit: data[id].datastream.unit.symbol,
				location: data[id].datastream.thing.description,
				sensor: data[id].datastream.sensor.label,
				property: data[id].datastream.property.symbol,
				slope: data[id].slope,
				intercept: data[id].intercept,
			}
		}), {});

		this.setState({data: reduced, refreshing: false});
	}

	handlePageChange(page) {
		this.setState({ page }, this.requestData);
	}

	handleRowClick(id) {
		this.setState({
			selected: {id, ...this.state.data[id]},
			isModalOpen: true,
		});
	}

	toggleModal() {
		this.setState({
			isModalOpen: !this.state.isModalOpen,
		});
	}

	handleInputChange(event) {
		const { name, value } = event.target;
		this.setState({
			selected: {...this.state.selected, [name]: value}
		});
	}

	handleFormSubmit(event) {
		event.preventDefault();
		const { slope, intercept } = this.state.selected;
		this.props.dispatch(updateData(`calibration/id/${this.state.selected.id}`, {slope, intercept}));
		this.setState({isModalOpen: false});
		this.requestData();
	}

	render() {

		if (!this.props.list.calibration || this.props.list.calibration.pending)
			return <Loading/>;

		const { data } = this.props.list.calibration;

		return (
			<Row>
				<Col>
					<Table>
						<Title>
							<T>calibration</T>
						</Title>
						<Thead>
							<Tr data={this.fields} />
						</Thead>
						<Tbody refreshing={this.state.refreshing}>
							<Tr data={this.state.data} onRowClick={this.handleRowClick} />
						</Tbody>
						<Pagination
							className="mx-auto"
							page={data.page}
							total={data.total_pages}
							onClick={(page) => {
								if (page !== data.page)
									this.handlePageChange(page);
							}}
						/>
					</Table>
					<Modal isOpen={this.state.isModalOpen} toggle={this.toggleModal}>
						<ModalHeader toggle={this.toggleModal}><T>calibration</T></ModalHeader>
						<ModalBody>
							<Form onSubmit={this.handleFormSubmit}>
								<Row>
									<Col>
										<FormGroup>
											<Label>
												<T>slope</T>
												<Input type="number" step="0.01" name="slope" value={this.state.selected.slope} onChange={this.handleInputChange} />
											</Label>
										</FormGroup>
										<FormGroup>
											<Label>
												<T>intercept</T>
												<Input type="number" step="0.01" name="intercept" value={this.state.selected.intercept} onChange={this.handleInputChange} />
											</Label>
										</FormGroup>
									</Col>
									<Col className="text-right">
										<Button type="submit" color="primary"><T>submit</T></Button>
									</Col>
								</Row>
							</Form>
						</ModalBody>
					</Modal>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	list: state.list,
});

Calibration = connect(mapStateToProps)(Calibration);

export default Calibration;
