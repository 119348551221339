import React, { Component } from 'react';

class Tbody extends Component {

	render() {

		const className = this.props.className ? 'tbody ' + this.props.className : 'tbody';

		return (
			<div className={className}>
				{this.props.children}
			</div>
		);
	}
}

export default Tbody;
