import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import moment from 'moment';
import './popupmessage.css';



class PopupMessage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    componentWillMount() {

    }

    render() {
        const { title, message, visible, toggle } = this.props;
        return (
            <Toast isOpen={visible} onClick={toggle} className="custom-toast">
                <ToastHeader toggle={toggle}>
                    {title}
                </ToastHeader>
                <ToastBody>
                    <div className="text-justify" dangerouslySetInnerHTML={{ __html: message }} />
                </ToastBody>
            </Toast>
        );
    }
}

PopupMessage.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
};

export default PopupMessage;
