import {Control} from 'ol/control';
import './airqualitylegend.css';

let ol_airqualitylegend_info = (function(Control) {
    function AirqualitylegendInfoControl(opt_options) {
        let options = opt_options || {};

        let element = document.createElement('div');
        element.setAttribute("id", "ol-airqualitylegendInfoDiv");
        element.className = 'ol-airqualitylegend-info ol-unselectable ol-control';

        Control.call(this, {
            element: element,
            target: options.target
        });
    }

    if ( Control ) AirqualitylegendInfoControl.__proto__ = Control;
    AirqualitylegendInfoControl.prototype = Object.create( Control && Control.prototype );
    AirqualitylegendInfoControl.prototype.constructor = AirqualitylegendInfoControl;

    return AirqualitylegendInfoControl;
}(Control));

ol_airqualitylegend_info.prototype.set = function(map) {

    let image_source = null;

    map.getLayerGroup().on('change', function (e) {
        const layers = e.target.get('layers').getArray();
        layers.forEach(function (item) {
            if(item.get('baseLayer') && item.get('visible')){
                // let layer_name = item.get('source').getParams().LAYERS;
                // let layer_url = item.get('source').url_;

                // image_source = layer_url + '?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=' + layer_name;

                image_source = item.get('legend');

                let div = document.getElementById('ol-airqualitylegendInfoDiv');
                div.innerHTML = '<img src="' + image_source + '" alt="Airqualitylegend" >';
                div.classList.add('ol-airqualitylegend-info-show');
            }
        });
    });

};

export default ol_airqualitylegend_info
